import { api } from "./baseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    checkoutSubscriptionVerificationCreate: build.mutation<
      CheckoutSubscriptionVerificationCreateApiResponse,
      CheckoutSubscriptionVerificationCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/checkout/subscription-verification/`,
        method: "POST",
        body: queryArg.subscriptionVerificationRequest,
      }),
    }),
    foodCreateRecipeCreate: build.mutation<FoodCreateRecipeCreateApiResponse, FoodCreateRecipeCreateApiArg>({
      query: (queryArg) => ({
        url: `/api/food/createRecipe/`,
        method: "POST",
        body: queryArg.createNewRecipeTemplateRequest,
      }),
    }),
    foodFoodList: build.query<FoodFoodListApiResponse, FoodFoodListApiArg>({
      query: (queryArg) => ({ url: `/api/food/food/`, params: { page: queryArg.page } }),
    }),
    foodFoodRetrieve: build.query<FoodFoodRetrieveApiResponse, FoodFoodRetrieveApiArg>({
      query: (queryArg) => ({ url: `/api/food/food/${queryArg.id}/` }),
    }),
    foodGenerateMealsList: build.query<FoodGenerateMealsListApiResponse, FoodGenerateMealsListApiArg>({
      query: (queryArg) => ({
        url: `/api/food/generateMeals/`,
        params: {
          author: queryArg.author,
          favourite: queryArg.favourite,
          ingredient_search: queryArg.ingredientSearch,
          kcal: queryArg.kcal,
          meal_slot_specification: queryArg.mealSlotSpecification,
          meal_types: queryArg.mealTypes,
          page: queryArg.page,
          page_size: queryArg.pageSize,
          preparation_time_min__gte: queryArg.preparationTimeMinGte,
          preparation_time_min__lte: queryArg.preparationTimeMinLte,
          protein: queryArg.protein,
          source_provider: queryArg.sourceProvider,
          tags: queryArg.tags,
          user: queryArg.user,
        },
      }),
    }),
    foodGenerateMealsCreate: build.mutation<FoodGenerateMealsCreateApiResponse, FoodGenerateMealsCreateApiArg>({
      query: (queryArg) => ({
        url: `/api/food/generateMeals/`,
        method: "POST",
        body: queryArg.generatedRecipeMealRequest,
      }),
    }),
    foodIngredientList: build.query<FoodIngredientListApiResponse, FoodIngredientListApiArg>({
      query: (queryArg) => ({ url: `/api/food/ingredient/`, params: { name: queryArg.name, page: queryArg.page } }),
    }),
    foodIngredientCreate: build.mutation<FoodIngredientCreateApiResponse, FoodIngredientCreateApiArg>({
      query: (queryArg) => ({ url: `/api/food/ingredient/`, method: "POST", body: queryArg.ingredientPostRequest }),
    }),
    foodIngredientRetrieve: build.query<FoodIngredientRetrieveApiResponse, FoodIngredientRetrieveApiArg>({
      query: (queryArg) => ({ url: `/api/food/ingredient/${queryArg.id}/` }),
    }),
    foodIngredientUpdate: build.mutation<FoodIngredientUpdateApiResponse, FoodIngredientUpdateApiArg>({
      query: (queryArg) => ({
        url: `/api/food/ingredient/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.ingredientRequest,
      }),
    }),
    foodIngredientPartialUpdate: build.mutation<
      FoodIngredientPartialUpdateApiResponse,
      FoodIngredientPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/food/ingredient/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedIngredientRequest,
      }),
    }),
    foodIngredientDestroy: build.mutation<FoodIngredientDestroyApiResponse, FoodIngredientDestroyApiArg>({
      query: (queryArg) => ({ url: `/api/food/ingredient/${queryArg.id}/`, method: "DELETE" }),
    }),
    foodIngredientToAvoidList: build.query<FoodIngredientToAvoidListApiResponse, FoodIngredientToAvoidListApiArg>({
      query: (queryArg) => ({ url: `/api/food/ingredientToAvoid/`, params: { page: queryArg.page } }),
    }),
    foodIngredientToAvoidRetrieve: build.query<
      FoodIngredientToAvoidRetrieveApiResponse,
      FoodIngredientToAvoidRetrieveApiArg
    >({
      query: (queryArg) => ({ url: `/api/food/ingredientToAvoid/${queryArg.id}/` }),
    }),
    foodPreferredSuggestedServingsList: build.query<
      FoodPreferredSuggestedServingsListApiResponse,
      FoodPreferredSuggestedServingsListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/food/preferredSuggestedServings/`,
        params: { organisation_id: queryArg.organisationId, page: queryArg.page, query: queryArg.query },
      }),
    }),
    foodPreferredSuggestedServingsCreate: build.mutation<
      FoodPreferredSuggestedServingsCreateApiResponse,
      FoodPreferredSuggestedServingsCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/food/preferredSuggestedServings/`,
        method: "POST",
        body: queryArg.preferredSuggestedServingRequest,
      }),
    }),
    foodPreferredSuggestedServingsRetrieve: build.query<
      FoodPreferredSuggestedServingsRetrieveApiResponse,
      FoodPreferredSuggestedServingsRetrieveApiArg
    >({
      query: (queryArg) => ({ url: `/api/food/preferredSuggestedServings/${queryArg.id}/` }),
    }),
    foodPreferredSuggestedServingsUpdate: build.mutation<
      FoodPreferredSuggestedServingsUpdateApiResponse,
      FoodPreferredSuggestedServingsUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/food/preferredSuggestedServings/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.preferredSuggestedServingRequest,
      }),
    }),
    foodPreferredSuggestedServingsPartialUpdate: build.mutation<
      FoodPreferredSuggestedServingsPartialUpdateApiResponse,
      FoodPreferredSuggestedServingsPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/food/preferredSuggestedServings/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedPreferredSuggestedServingRequest,
      }),
    }),
    foodPreferredSuggestedServingsDestroy: build.mutation<
      FoodPreferredSuggestedServingsDestroyApiResponse,
      FoodPreferredSuggestedServingsDestroyApiArg
    >({
      query: (queryArg) => ({ url: `/api/food/preferredSuggestedServings/${queryArg.id}/`, method: "DELETE" }),
    }),
    foodQuickAddMealList: build.query<FoodQuickAddMealListApiResponse, FoodQuickAddMealListApiArg>({
      query: (queryArg) => ({ url: `/api/food/quickAddMeal/`, params: { page: queryArg.page } }),
    }),
    foodQuickAddMealCreate: build.mutation<FoodQuickAddMealCreateApiResponse, FoodQuickAddMealCreateApiArg>({
      query: (queryArg) => ({ url: `/api/food/quickAddMeal/`, method: "POST", body: queryArg.quickAddMealRequest }),
    }),
    foodQuickAddMealRetrieve: build.query<FoodQuickAddMealRetrieveApiResponse, FoodQuickAddMealRetrieveApiArg>({
      query: (queryArg) => ({ url: `/api/food/quickAddMeal/${queryArg.id}/` }),
    }),
    foodQuickAddMealUpdate: build.mutation<FoodQuickAddMealUpdateApiResponse, FoodQuickAddMealUpdateApiArg>({
      query: (queryArg) => ({
        url: `/api/food/quickAddMeal/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.quickAddMealRequest,
      }),
    }),
    foodQuickAddMealPartialUpdate: build.mutation<
      FoodQuickAddMealPartialUpdateApiResponse,
      FoodQuickAddMealPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/food/quickAddMeal/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedQuickAddMealRequest,
      }),
    }),
    foodQuickAddMealDestroy: build.mutation<FoodQuickAddMealDestroyApiResponse, FoodQuickAddMealDestroyApiArg>({
      query: (queryArg) => ({ url: `/api/food/quickAddMeal/${queryArg.id}/`, method: "DELETE" }),
    }),
    foodRecipeFeedbackLineItemList: build.query<
      FoodRecipeFeedbackLineItemListApiResponse,
      FoodRecipeFeedbackLineItemListApiArg
    >({
      query: (queryArg) => ({ url: `/api/food/recipeFeedbackLineItem/`, params: { page: queryArg.page } }),
    }),
    foodRecipeFeedbackLineItemCreate: build.mutation<
      FoodRecipeFeedbackLineItemCreateApiResponse,
      FoodRecipeFeedbackLineItemCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/food/recipeFeedbackLineItem/`,
        method: "POST",
        body: queryArg.recipeFeedbackLineItemRequest,
      }),
    }),
    foodRecipeFeedbackLineItemRetrieve: build.query<
      FoodRecipeFeedbackLineItemRetrieveApiResponse,
      FoodRecipeFeedbackLineItemRetrieveApiArg
    >({
      query: (queryArg) => ({ url: `/api/food/recipeFeedbackLineItem/${queryArg.id}/` }),
    }),
    foodRecipeFeedbackLineItemUpdate: build.mutation<
      FoodRecipeFeedbackLineItemUpdateApiResponse,
      FoodRecipeFeedbackLineItemUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/food/recipeFeedbackLineItem/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.recipeFeedbackLineItemRequest,
      }),
    }),
    foodRecipeFeedbackLineItemPartialUpdate: build.mutation<
      FoodRecipeFeedbackLineItemPartialUpdateApiResponse,
      FoodRecipeFeedbackLineItemPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/food/recipeFeedbackLineItem/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedRecipeFeedbackLineItemRequest,
      }),
    }),
    foodRecipeFeedbackLineItemDestroy: build.mutation<
      FoodRecipeFeedbackLineItemDestroyApiResponse,
      FoodRecipeFeedbackLineItemDestroyApiArg
    >({
      query: (queryArg) => ({ url: `/api/food/recipeFeedbackLineItem/${queryArg.id}/`, method: "DELETE" }),
    }),
    foodRecipeMealList: build.query<FoodRecipeMealListApiResponse, FoodRecipeMealListApiArg>({
      query: (queryArg) => ({ url: `/api/food/recipeMeal/`, params: { page: queryArg.page } }),
    }),
    foodRecipeMealCreate: build.mutation<FoodRecipeMealCreateApiResponse, FoodRecipeMealCreateApiArg>({
      query: (queryArg) => ({ url: `/api/food/recipeMeal/`, method: "POST", body: queryArg.recipeMealRequest }),
    }),
    foodRecipeMealRetrieve: build.query<FoodRecipeMealRetrieveApiResponse, FoodRecipeMealRetrieveApiArg>({
      query: (queryArg) => ({ url: `/api/food/recipeMeal/${queryArg.id}/` }),
    }),
    foodRecipeMealUpdate: build.mutation<FoodRecipeMealUpdateApiResponse, FoodRecipeMealUpdateApiArg>({
      query: (queryArg) => ({
        url: `/api/food/recipeMeal/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.recipeMealRequest,
      }),
    }),
    foodRecipeMealPartialUpdate: build.mutation<
      FoodRecipeMealPartialUpdateApiResponse,
      FoodRecipeMealPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/food/recipeMeal/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedRecipeMealRequest,
      }),
    }),
    foodRecipeMealDestroy: build.mutation<FoodRecipeMealDestroyApiResponse, FoodRecipeMealDestroyApiArg>({
      query: (queryArg) => ({ url: `/api/food/recipeMeal/${queryArg.id}/`, method: "DELETE" }),
    }),
    foodRecipeTagList: build.query<FoodRecipeTagListApiResponse, FoodRecipeTagListApiArg>({
      query: (queryArg) => ({ url: `/api/food/recipeTag/`, params: { page: queryArg.page } }),
    }),
    foodRecipeTagRetrieve: build.query<FoodRecipeTagRetrieveApiResponse, FoodRecipeTagRetrieveApiArg>({
      query: (queryArg) => ({ url: `/api/food/recipeTag/${queryArg.id}/` }),
    }),
    foodRecipeTemplateList: build.query<FoodRecipeTemplateListApiResponse, FoodRecipeTemplateListApiArg>({
      query: (queryArg) => ({
        url: `/api/food/recipeTemplate/`,
        params: {
          author: queryArg.author,
          favourite: queryArg.favourite,
          ingredient_search: queryArg.ingredientSearch,
          meal_types: queryArg.mealTypes,
          page: queryArg.page,
          preparation_time_min__gte: queryArg.preparationTimeMinGte,
          preparation_time_min__lte: queryArg.preparationTimeMinLte,
          source_provider: queryArg.sourceProvider,
          tags: queryArg.tags,
          user: queryArg.user,
        },
      }),
    }),
    foodRecipeTemplateCreate: build.mutation<FoodRecipeTemplateCreateApiResponse, FoodRecipeTemplateCreateApiArg>({
      query: (queryArg) => ({ url: `/api/food/recipeTemplate/`, method: "POST", body: queryArg.recipeTemplateRequest }),
    }),
    foodRecipeTemplateRetrieve: build.query<FoodRecipeTemplateRetrieveApiResponse, FoodRecipeTemplateRetrieveApiArg>({
      query: (queryArg) => ({ url: `/api/food/recipeTemplate/${queryArg.id}/` }),
    }),
    foodRecipeTemplateUpdate: build.mutation<FoodRecipeTemplateUpdateApiResponse, FoodRecipeTemplateUpdateApiArg>({
      query: (queryArg) => ({
        url: `/api/food/recipeTemplate/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.recipeTemplateRequest,
      }),
    }),
    foodRecipeTemplatePartialUpdate: build.mutation<
      FoodRecipeTemplatePartialUpdateApiResponse,
      FoodRecipeTemplatePartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/food/recipeTemplate/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedRecipeTemplateRequest,
      }),
    }),
    foodRecipeTemplateDestroy: build.mutation<FoodRecipeTemplateDestroyApiResponse, FoodRecipeTemplateDestroyApiArg>({
      query: (queryArg) => ({ url: `/api/food/recipeTemplate/${queryArg.id}/`, method: "DELETE" }),
    }),
    foodSearchList: build.query<FoodSearchListApiResponse, FoodSearchListApiArg>({
      query: (queryArg) => ({ url: `/api/food/search`, params: { query: queryArg.query } }),
    }),
    foodSearchAutocompleteRetrieve: build.query<
      FoodSearchAutocompleteRetrieveApiResponse,
      FoodSearchAutocompleteRetrieveApiArg
    >({
      query: (queryArg) => ({ url: `/api/food/search/autocomplete`, params: { query: queryArg.query } }),
    }),
    foodSearchByBarcodeRetrieve: build.query<FoodSearchByBarcodeRetrieveApiResponse, FoodSearchByBarcodeRetrieveApiArg>(
      {
        query: (queryArg) => ({ url: `/api/food/search/byBarcode`, params: { barcode: queryArg.barcode } }),
      }
    ),
    foodSingleFoodMealList: build.query<FoodSingleFoodMealListApiResponse, FoodSingleFoodMealListApiArg>({
      query: (queryArg) => ({ url: `/api/food/singleFoodMeal/`, params: { page: queryArg.page } }),
    }),
    foodSingleFoodMealCreate: build.mutation<FoodSingleFoodMealCreateApiResponse, FoodSingleFoodMealCreateApiArg>({
      query: (queryArg) => ({ url: `/api/food/singleFoodMeal/`, method: "POST", body: queryArg.singleFoodMealRequest }),
    }),
    foodSingleFoodMealRetrieve: build.query<FoodSingleFoodMealRetrieveApiResponse, FoodSingleFoodMealRetrieveApiArg>({
      query: (queryArg) => ({ url: `/api/food/singleFoodMeal/${queryArg.id}/` }),
    }),
    foodSingleFoodMealUpdate: build.mutation<FoodSingleFoodMealUpdateApiResponse, FoodSingleFoodMealUpdateApiArg>({
      query: (queryArg) => ({
        url: `/api/food/singleFoodMeal/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.singleFoodMealRequest,
      }),
    }),
    foodSingleFoodMealPartialUpdate: build.mutation<
      FoodSingleFoodMealPartialUpdateApiResponse,
      FoodSingleFoodMealPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/food/singleFoodMeal/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedSingleFoodMealRequest,
      }),
    }),
    foodSingleFoodMealDestroy: build.mutation<FoodSingleFoodMealDestroyApiResponse, FoodSingleFoodMealDestroyApiArg>({
      query: (queryArg) => ({ url: `/api/food/singleFoodMeal/${queryArg.id}/`, method: "DELETE" }),
    }),
    foodSuggestedServingList: build.query<FoodSuggestedServingListApiResponse, FoodSuggestedServingListApiArg>({
      query: (queryArg) => ({ url: `/api/food/suggestedServing/`, params: { page: queryArg.page } }),
    }),
    foodSuggestedServingRetrieve: build.query<
      FoodSuggestedServingRetrieveApiResponse,
      FoodSuggestedServingRetrieveApiArg
    >({
      query: (queryArg) => ({ url: `/api/food/suggestedServing/${queryArg.id}/` }),
    }),
    foodSuggestedServingSearchList: build.query<
      FoodSuggestedServingSearchListApiResponse,
      FoodSuggestedServingSearchListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/food/suggestedServingSearch/`,
        params: { food__source_provider: queryArg.foodSourceProvider, page: queryArg.page, query: queryArg.query },
      }),
    }),
    foodSuggestedServingSearchRetrieve: build.query<
      FoodSuggestedServingSearchRetrieveApiResponse,
      FoodSuggestedServingSearchRetrieveApiArg
    >({
      query: (queryArg) => ({ url: `/api/food/suggestedServingSearch/${queryArg.id}/` }),
    }),
    measurementsUserBodyFatPercentageList: build.query<
      MeasurementsUserBodyFatPercentageListApiResponse,
      MeasurementsUserBodyFatPercentageListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/measurements/UserBodyFatPercentage/`,
        params: { page: queryArg.page, user: queryArg.user },
      }),
    }),
    measurementsUserBodyFatPercentageCreate: build.mutation<
      MeasurementsUserBodyFatPercentageCreateApiResponse,
      MeasurementsUserBodyFatPercentageCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/measurements/UserBodyFatPercentage/`,
        method: "POST",
        body: queryArg.userBodyFatPercentageRequest,
      }),
    }),
    measurementsUserBodyFatPercentageRetrieve: build.query<
      MeasurementsUserBodyFatPercentageRetrieveApiResponse,
      MeasurementsUserBodyFatPercentageRetrieveApiArg
    >({
      query: (queryArg) => ({ url: `/api/measurements/UserBodyFatPercentage/${queryArg.id}/` }),
    }),
    measurementsUserBodyFatPercentageUpdate: build.mutation<
      MeasurementsUserBodyFatPercentageUpdateApiResponse,
      MeasurementsUserBodyFatPercentageUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/measurements/UserBodyFatPercentage/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.userBodyFatPercentageRequest,
      }),
    }),
    measurementsUserBodyFatPercentagePartialUpdate: build.mutation<
      MeasurementsUserBodyFatPercentagePartialUpdateApiResponse,
      MeasurementsUserBodyFatPercentagePartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/measurements/UserBodyFatPercentage/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedUserBodyFatPercentageRequest,
      }),
    }),
    measurementsUserBodyFatPercentageDestroy: build.mutation<
      MeasurementsUserBodyFatPercentageDestroyApiResponse,
      MeasurementsUserBodyFatPercentageDestroyApiArg
    >({
      query: (queryArg) => ({ url: `/api/measurements/UserBodyFatPercentage/${queryArg.id}/`, method: "DELETE" }),
    }),
    measurementsUserDistanceList: build.query<
      MeasurementsUserDistanceListApiResponse,
      MeasurementsUserDistanceListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/measurements/UserDistance/`,
        params: { page: queryArg.page, user: queryArg.user },
      }),
    }),
    measurementsUserDistanceCreate: build.mutation<
      MeasurementsUserDistanceCreateApiResponse,
      MeasurementsUserDistanceCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/measurements/UserDistance/`,
        method: "POST",
        body: queryArg.userDistanceRequest,
      }),
    }),
    measurementsUserDistanceRetrieve: build.query<
      MeasurementsUserDistanceRetrieveApiResponse,
      MeasurementsUserDistanceRetrieveApiArg
    >({
      query: (queryArg) => ({ url: `/api/measurements/UserDistance/${queryArg.id}/` }),
    }),
    measurementsUserDistanceUpdate: build.mutation<
      MeasurementsUserDistanceUpdateApiResponse,
      MeasurementsUserDistanceUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/measurements/UserDistance/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.userDistanceRequest,
      }),
    }),
    measurementsUserDistancePartialUpdate: build.mutation<
      MeasurementsUserDistancePartialUpdateApiResponse,
      MeasurementsUserDistancePartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/measurements/UserDistance/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedUserDistanceRequest,
      }),
    }),
    measurementsUserDistanceDestroy: build.mutation<
      MeasurementsUserDistanceDestroyApiResponse,
      MeasurementsUserDistanceDestroyApiArg
    >({
      query: (queryArg) => ({ url: `/api/measurements/UserDistance/${queryArg.id}/`, method: "DELETE" }),
    }),
    measurementsUserSleepList: build.query<MeasurementsUserSleepListApiResponse, MeasurementsUserSleepListApiArg>({
      query: (queryArg) => ({
        url: `/api/measurements/UserSleep/`,
        params: { page: queryArg.page, user: queryArg.user },
      }),
    }),
    measurementsUserSleepCreate: build.mutation<
      MeasurementsUserSleepCreateApiResponse,
      MeasurementsUserSleepCreateApiArg
    >({
      query: (queryArg) => ({ url: `/api/measurements/UserSleep/`, method: "POST", body: queryArg.userSleepRequest }),
    }),
    measurementsUserSleepRetrieve: build.query<
      MeasurementsUserSleepRetrieveApiResponse,
      MeasurementsUserSleepRetrieveApiArg
    >({
      query: (queryArg) => ({ url: `/api/measurements/UserSleep/${queryArg.id}/` }),
    }),
    measurementsUserSleepUpdate: build.mutation<
      MeasurementsUserSleepUpdateApiResponse,
      MeasurementsUserSleepUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/measurements/UserSleep/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.userSleepRequest,
      }),
    }),
    measurementsUserSleepPartialUpdate: build.mutation<
      MeasurementsUserSleepPartialUpdateApiResponse,
      MeasurementsUserSleepPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/measurements/UserSleep/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedUserSleepRequest,
      }),
    }),
    measurementsUserSleepDestroy: build.mutation<
      MeasurementsUserSleepDestroyApiResponse,
      MeasurementsUserSleepDestroyApiArg
    >({
      query: (queryArg) => ({ url: `/api/measurements/UserSleep/${queryArg.id}/`, method: "DELETE" }),
    }),
    measurementsUserStressList: build.query<MeasurementsUserStressListApiResponse, MeasurementsUserStressListApiArg>({
      query: (queryArg) => ({
        url: `/api/measurements/UserStress/`,
        params: { page: queryArg.page, user: queryArg.user },
      }),
    }),
    measurementsUserStressCreate: build.mutation<
      MeasurementsUserStressCreateApiResponse,
      MeasurementsUserStressCreateApiArg
    >({
      query: (queryArg) => ({ url: `/api/measurements/UserStress/`, method: "POST", body: queryArg.userStressRequest }),
    }),
    measurementsUserStressRetrieve: build.query<
      MeasurementsUserStressRetrieveApiResponse,
      MeasurementsUserStressRetrieveApiArg
    >({
      query: (queryArg) => ({ url: `/api/measurements/UserStress/${queryArg.id}/` }),
    }),
    measurementsUserStressUpdate: build.mutation<
      MeasurementsUserStressUpdateApiResponse,
      MeasurementsUserStressUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/measurements/UserStress/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.userStressRequest,
      }),
    }),
    measurementsUserStressPartialUpdate: build.mutation<
      MeasurementsUserStressPartialUpdateApiResponse,
      MeasurementsUserStressPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/measurements/UserStress/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedUserStressRequest,
      }),
    }),
    measurementsUserStressDestroy: build.mutation<
      MeasurementsUserStressDestroyApiResponse,
      MeasurementsUserStressDestroyApiArg
    >({
      query: (queryArg) => ({ url: `/api/measurements/UserStress/${queryArg.id}/`, method: "DELETE" }),
    }),
    measurementsDeleteMeasurementCreate: build.mutation<
      MeasurementsDeleteMeasurementCreateApiResponse,
      MeasurementsDeleteMeasurementCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/measurements/deleteMeasurement/`,
        method: "POST",
        body: queryArg.deleteMeasurementRequest,
      }),
    }),
    measurementsUserWeightList: build.query<MeasurementsUserWeightListApiResponse, MeasurementsUserWeightListApiArg>({
      query: (queryArg) => ({
        url: `/api/measurements/userWeight/`,
        params: { page: queryArg.page, user: queryArg.user },
      }),
    }),
    measurementsUserWeightCreate: build.mutation<
      MeasurementsUserWeightCreateApiResponse,
      MeasurementsUserWeightCreateApiArg
    >({
      query: (queryArg) => ({ url: `/api/measurements/userWeight/`, method: "POST", body: queryArg.userWeightRequest }),
    }),
    measurementsUserWeightRetrieve: build.query<
      MeasurementsUserWeightRetrieveApiResponse,
      MeasurementsUserWeightRetrieveApiArg
    >({
      query: (queryArg) => ({ url: `/api/measurements/userWeight/${queryArg.id}/` }),
    }),
    measurementsUserWeightUpdate: build.mutation<
      MeasurementsUserWeightUpdateApiResponse,
      MeasurementsUserWeightUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/measurements/userWeight/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.userWeightRequest,
      }),
    }),
    measurementsUserWeightPartialUpdate: build.mutation<
      MeasurementsUserWeightPartialUpdateApiResponse,
      MeasurementsUserWeightPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/measurements/userWeight/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedUserWeightRequest,
      }),
    }),
    measurementsUserWeightDestroy: build.mutation<
      MeasurementsUserWeightDestroyApiResponse,
      MeasurementsUserWeightDestroyApiArg
    >({
      query: (queryArg) => ({ url: `/api/measurements/userWeight/${queryArg.id}/`, method: "DELETE" }),
    }),
    plannerAutoPlanDayCreate: build.mutation<PlannerAutoPlanDayCreateApiResponse, PlannerAutoPlanDayCreateApiArg>({
      query: (queryArg) => ({ url: `/api/planner/autoPlanDay`, method: "POST", body: queryArg.autoPlannerRequest }),
    }),
    plannerAutoPlanDaysCreate: build.mutation<PlannerAutoPlanDaysCreateApiResponse, PlannerAutoPlanDaysCreateApiArg>({
      query: (queryArg) => ({ url: `/api/planner/autoPlanDays`, method: "POST", body: queryArg.autoPlanDaysRequest }),
    }),
    plannerCalendarDayList: build.query<PlannerCalendarDayListApiResponse, PlannerCalendarDayListApiArg>({
      query: (queryArg) => ({
        url: `/api/planner/calendarDay/`,
        params: {
          date__gte: queryArg.dateGte,
          date__lt: queryArg.dateLt,
          date__lte: queryArg.dateLte,
          page: queryArg.page,
          user: queryArg.user,
        },
      }),
    }),
    plannerCalendarDayCreate: build.mutation<PlannerCalendarDayCreateApiResponse, PlannerCalendarDayCreateApiArg>({
      query: (queryArg) => ({ url: `/api/planner/calendarDay/`, method: "POST", body: queryArg.calendarDayRequest }),
    }),
    plannerCalendarDayRetrieve: build.query<PlannerCalendarDayRetrieveApiResponse, PlannerCalendarDayRetrieveApiArg>({
      query: (queryArg) => ({ url: `/api/planner/calendarDay/${queryArg.id}/` }),
    }),
    plannerCalendarDayUpdate: build.mutation<PlannerCalendarDayUpdateApiResponse, PlannerCalendarDayUpdateApiArg>({
      query: (queryArg) => ({
        url: `/api/planner/calendarDay/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.calendarDayRequest,
      }),
    }),
    plannerCalendarDayPartialUpdate: build.mutation<
      PlannerCalendarDayPartialUpdateApiResponse,
      PlannerCalendarDayPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/planner/calendarDay/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedCalendarDayRequest,
      }),
    }),
    plannerCalendarDayDestroy: build.mutation<PlannerCalendarDayDestroyApiResponse, PlannerCalendarDayDestroyApiArg>({
      query: (queryArg) => ({ url: `/api/planner/calendarDay/${queryArg.id}/`, method: "DELETE" }),
    }),
    plannerCalendarItemList: build.query<PlannerCalendarItemListApiResponse, PlannerCalendarItemListApiArg>({
      query: (queryArg) => ({
        url: `/api/planner/calendarItem/`,
        params: { calendar_day__user: queryArg.calendarDayUser, page: queryArg.page },
      }),
    }),
    plannerCalendarItemCreate: build.mutation<PlannerCalendarItemCreateApiResponse, PlannerCalendarItemCreateApiArg>({
      query: (queryArg) => ({ url: `/api/planner/calendarItem/`, method: "POST", body: queryArg.calendarItemRequest }),
    }),
    plannerCalendarItemRetrieve: build.query<PlannerCalendarItemRetrieveApiResponse, PlannerCalendarItemRetrieveApiArg>(
      {
        query: (queryArg) => ({ url: `/api/planner/calendarItem/${queryArg.id}/` }),
      }
    ),
    plannerCalendarItemUpdate: build.mutation<PlannerCalendarItemUpdateApiResponse, PlannerCalendarItemUpdateApiArg>({
      query: (queryArg) => ({
        url: `/api/planner/calendarItem/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.calendarItemRequest,
      }),
    }),
    plannerCalendarItemPartialUpdate: build.mutation<
      PlannerCalendarItemPartialUpdateApiResponse,
      PlannerCalendarItemPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/planner/calendarItem/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedCalendarItemRequest,
      }),
    }),
    plannerCalendarItemDestroy: build.mutation<PlannerCalendarItemDestroyApiResponse, PlannerCalendarItemDestroyApiArg>(
      {
        query: (queryArg) => ({ url: `/api/planner/calendarItem/${queryArg.id}/`, method: "DELETE" }),
      }
    ),
    plannerCopyMealsFromDateCreate: build.mutation<
      PlannerCopyMealsFromDateCreateApiResponse,
      PlannerCopyMealsFromDateCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/planner/copyMealsFromDate/`,
        method: "POST",
        body: queryArg.copyFromDateRequest,
      }),
    }),
    plannerCopyMealsToMealMomentCreate: build.mutation<
      PlannerCopyMealsToMealMomentCreateApiResponse,
      PlannerCopyMealsToMealMomentCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/planner/copyMealsToMealMoment/`,
        method: "POST",
        body: queryArg.copyMealsToMealMomentRequest,
      }),
    }),
    plannerEditRecipeMealPortionsUpdate: build.mutation<
      PlannerEditRecipeMealPortionsUpdateApiResponse,
      PlannerEditRecipeMealPortionsUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/planner/editRecipeMealPortions/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.recipeMealPotionsRequest,
      }),
    }),
    plannerGroceryListList: build.query<PlannerGroceryListListApiResponse, PlannerGroceryListListApiArg>({
      query: (queryArg) => ({ url: `/api/planner/groceryList/`, params: { page: queryArg.page, user: queryArg.user } }),
    }),
    plannerGroceryListCreate: build.mutation<PlannerGroceryListCreateApiResponse, PlannerGroceryListCreateApiArg>({
      query: (queryArg) => ({
        url: `/api/planner/groceryList/`,
        method: "POST",
        body: queryArg.groceryListUpdateCreateRequest,
      }),
    }),
    plannerGroceryListRetrieve: build.query<PlannerGroceryListRetrieveApiResponse, PlannerGroceryListRetrieveApiArg>({
      query: (queryArg) => ({ url: `/api/planner/groceryList/${queryArg.id}/` }),
    }),
    plannerGroceryListUpdate: build.mutation<PlannerGroceryListUpdateApiResponse, PlannerGroceryListUpdateApiArg>({
      query: (queryArg) => ({ url: `/api/planner/groceryList/${queryArg.id}/`, method: "PUT" }),
    }),
    plannerGroceryListPartialUpdate: build.mutation<
      PlannerGroceryListPartialUpdateApiResponse,
      PlannerGroceryListPartialUpdateApiArg
    >({
      query: (queryArg) => ({ url: `/api/planner/groceryList/${queryArg.id}/`, method: "PATCH" }),
    }),
    plannerGroceryListDestroy: build.mutation<PlannerGroceryListDestroyApiResponse, PlannerGroceryListDestroyApiArg>({
      query: (queryArg) => ({ url: `/api/planner/groceryList/${queryArg.id}/`, method: "DELETE" }),
    }),
    plannerGroceryListItemPartialUpdate: build.mutation<
      PlannerGroceryListItemPartialUpdateApiResponse,
      PlannerGroceryListItemPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/planner/groceryListItem/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedGroceryListItemUpdateRequest,
      }),
    }),
    plannerPlanGeneratedRecipeMealCreate: build.mutation<
      PlannerPlanGeneratedRecipeMealCreateApiResponse,
      PlannerPlanGeneratedRecipeMealCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/planner/planGeneratedRecipeMeal/`,
        method: "POST",
        body: queryArg.planGeneratedRecipeMealRequest,
      }),
    }),
    plannerPlanSingleFoodMealCreate: build.mutation<
      PlannerPlanSingleFoodMealCreateApiResponse,
      PlannerPlanSingleFoodMealCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/planner/planSingleFoodMeal/`,
        method: "POST",
        body: queryArg.planSingleFoodMealRequest,
      }),
    }),
    plannerSaveAndPlanRecipeMealCreate: build.mutation<
      PlannerSaveAndPlanRecipeMealCreateApiResponse,
      PlannerSaveAndPlanRecipeMealCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/planner/saveAndPlanRecipeMeal/`,
        method: "POST",
        body: queryArg.saveAndPlanRecipeTemplateRequest,
      }),
    }),
    schemaRetrieve: build.query<SchemaRetrieveApiResponse, SchemaRetrieveApiArg>({
      query: (queryArg) => ({ url: `/api/schema/`, params: { format: queryArg.format, lang: queryArg.lang } }),
    }),
    usersAuthTokenLoginCreate: build.mutation<UsersAuthTokenLoginCreateApiResponse, UsersAuthTokenLoginCreateApiArg>({
      query: (queryArg) => ({ url: `/api/users/auth/token/login/`, method: "POST", body: queryArg.tokenCreateRequest }),
    }),
    usersAuthTokenLogoutCreate: build.mutation<UsersAuthTokenLogoutCreateApiResponse, UsersAuthTokenLogoutCreateApiArg>(
      {
        query: () => ({ url: `/api/users/auth/token/logout/`, method: "POST" }),
      }
    ),
    usersAuthUsersList: build.query<UsersAuthUsersListApiResponse, UsersAuthUsersListApiArg>({
      query: (queryArg) => ({ url: `/api/users/auth/users/`, params: { page: queryArg.page } }),
    }),
    usersAuthUsersCreate: build.mutation<UsersAuthUsersCreateApiResponse, UsersAuthUsersCreateApiArg>({
      query: (queryArg) => ({ url: `/api/users/auth/users/`, method: "POST", body: queryArg.customUserCreateRequest }),
    }),
    usersAuthUsersRetrieve: build.query<UsersAuthUsersRetrieveApiResponse, UsersAuthUsersRetrieveApiArg>({
      query: (queryArg) => ({ url: `/api/users/auth/users/${queryArg.id}/` }),
    }),
    usersAuthUsersUpdate: build.mutation<UsersAuthUsersUpdateApiResponse, UsersAuthUsersUpdateApiArg>({
      query: (queryArg) => ({
        url: `/api/users/auth/users/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.userRequest,
      }),
    }),
    usersAuthUsersPartialUpdate: build.mutation<
      UsersAuthUsersPartialUpdateApiResponse,
      UsersAuthUsersPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/auth/users/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedUserRequest,
      }),
    }),
    usersAuthUsersDestroy: build.mutation<UsersAuthUsersDestroyApiResponse, UsersAuthUsersDestroyApiArg>({
      query: (queryArg) => ({ url: `/api/users/auth/users/${queryArg.id}/`, method: "DELETE" }),
    }),
    usersAuthUsersActivationCreate: build.mutation<
      UsersAuthUsersActivationCreateApiResponse,
      UsersAuthUsersActivationCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/auth/users/activation/`,
        method: "POST",
        body: queryArg.activationRequest,
      }),
    }),
    usersAuthUsersMeRetrieve: build.query<UsersAuthUsersMeRetrieveApiResponse, UsersAuthUsersMeRetrieveApiArg>({
      query: () => ({ url: `/api/users/auth/users/me/` }),
    }),
    usersAuthUsersMeUpdate: build.mutation<UsersAuthUsersMeUpdateApiResponse, UsersAuthUsersMeUpdateApiArg>({
      query: (queryArg) => ({ url: `/api/users/auth/users/me/`, method: "PUT", body: queryArg.userRequest }),
    }),
    usersAuthUsersMePartialUpdate: build.mutation<
      UsersAuthUsersMePartialUpdateApiResponse,
      UsersAuthUsersMePartialUpdateApiArg
    >({
      query: (queryArg) => ({ url: `/api/users/auth/users/me/`, method: "PATCH", body: queryArg.patchedUserRequest }),
    }),
    usersAuthUsersMeDestroy: build.mutation<UsersAuthUsersMeDestroyApiResponse, UsersAuthUsersMeDestroyApiArg>({
      query: () => ({ url: `/api/users/auth/users/me/`, method: "DELETE" }),
    }),
    usersAuthUsersResendActivationCreate: build.mutation<
      UsersAuthUsersResendActivationCreateApiResponse,
      UsersAuthUsersResendActivationCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/auth/users/resend_activation/`,
        method: "POST",
        body: queryArg.sendEmailResetRequest,
      }),
    }),
    usersAuthUsersResetEmailCreate: build.mutation<
      UsersAuthUsersResetEmailCreateApiResponse,
      UsersAuthUsersResetEmailCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/auth/users/reset_email/`,
        method: "POST",
        body: queryArg.sendEmailResetRequest,
      }),
    }),
    usersAuthUsersResetEmailConfirmCreate: build.mutation<
      UsersAuthUsersResetEmailConfirmCreateApiResponse,
      UsersAuthUsersResetEmailConfirmCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/auth/users/reset_email_confirm/`,
        method: "POST",
        body: queryArg.usernameResetConfirmRequest,
      }),
    }),
    usersAuthUsersResetPasswordCreate: build.mutation<
      UsersAuthUsersResetPasswordCreateApiResponse,
      UsersAuthUsersResetPasswordCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/auth/users/reset_password/`,
        method: "POST",
        body: queryArg.sendEmailResetRequest,
      }),
    }),
    usersAuthUsersResetPasswordConfirmCreate: build.mutation<
      UsersAuthUsersResetPasswordConfirmCreateApiResponse,
      UsersAuthUsersResetPasswordConfirmCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/auth/users/reset_password_confirm/`,
        method: "POST",
        body: queryArg.passwordResetConfirmRequest,
      }),
    }),
    usersAuthUsersSetEmailCreate: build.mutation<
      UsersAuthUsersSetEmailCreateApiResponse,
      UsersAuthUsersSetEmailCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/auth/users/set_email/`,
        method: "POST",
        body: queryArg.setUsernameRequest,
      }),
    }),
    usersAuthUsersSetPasswordCreate: build.mutation<
      UsersAuthUsersSetPasswordCreateApiResponse,
      UsersAuthUsersSetPasswordCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/auth/users/set_password/`,
        method: "POST",
        body: queryArg.setPasswordRequest,
      }),
    }),
    usersBrandList: build.query<UsersBrandListApiResponse, UsersBrandListApiArg>({
      query: (queryArg) => ({ url: `/api/users/brand/`, params: { page: queryArg.page } }),
    }),
    usersBrandRetrieve: build.query<UsersBrandRetrieveApiResponse, UsersBrandRetrieveApiArg>({
      query: (queryArg) => ({ url: `/api/users/brand/${queryArg.subdomain}/` }),
    }),
    usersCancelDealCreate: build.mutation<UsersCancelDealCreateApiResponse, UsersCancelDealCreateApiArg>({
      query: (queryArg) => ({ url: `/api/users/cancelDeal`, method: "POST", body: queryArg.cancelSubscriptionRequest }),
    }),
    usersClientsList: build.query<UsersClientsListApiResponse, UsersClientsListApiArg>({
      query: () => ({ url: `/api/users/clients/` }),
    }),
    usersClientsCreate: build.mutation<UsersClientsCreateApiResponse, UsersClientsCreateApiArg>({
      query: (queryArg) => ({ url: `/api/users/clients/`, method: "POST", body: queryArg.userRequest }),
    }),
    usersClientsRetrieve: build.query<UsersClientsRetrieveApiResponse, UsersClientsRetrieveApiArg>({
      query: (queryArg) => ({ url: `/api/users/clients/${queryArg.id}/` }),
    }),
    usersClientsUpdate: build.mutation<UsersClientsUpdateApiResponse, UsersClientsUpdateApiArg>({
      query: (queryArg) => ({ url: `/api/users/clients/${queryArg.id}/`, method: "PUT", body: queryArg.userRequest }),
    }),
    usersClientsPartialUpdate: build.mutation<UsersClientsPartialUpdateApiResponse, UsersClientsPartialUpdateApiArg>({
      query: (queryArg) => ({
        url: `/api/users/clients/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedUserRequest,
      }),
    }),
    usersClientsDestroy: build.mutation<UsersClientsDestroyApiResponse, UsersClientsDestroyApiArg>({
      query: (queryArg) => ({ url: `/api/users/clients/${queryArg.id}/`, method: "DELETE" }),
    }),
    usersCreateLoginSignupPortalCreate: build.mutation<
      UsersCreateLoginSignupPortalCreateApiResponse,
      UsersCreateLoginSignupPortalCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/createLoginSignupPortal/`,
        method: "POST",
        body: queryArg.loginSignupPortalRequest,
      }),
    }),
    usersCreateMagicLinkCreate: build.mutation<UsersCreateMagicLinkCreateApiResponse, UsersCreateMagicLinkCreateApiArg>(
      {
        query: (queryArg) => ({
          url: `/api/users/createMagicLink/`,
          method: "POST",
          body: queryArg.magicLinkCreateRequest,
        }),
      }
    ),
    usersCreateOtpCreate: build.mutation<UsersCreateOtpCreateApiResponse, UsersCreateOtpCreateApiArg>({
      query: (queryArg) => ({ url: `/api/users/createOTP/`, method: "POST", body: queryArg.sendOtpRequest }),
    }),
    usersCustomerPortalRedirectRetrieve: build.query<
      UsersCustomerPortalRedirectRetrieveApiResponse,
      UsersCustomerPortalRedirectRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/customerPortalRedirect/`,
        params: { email: queryArg.email, id: queryArg.id },
      }),
    }),
    usersExerciseInstanceList: build.query<UsersExerciseInstanceListApiResponse, UsersExerciseInstanceListApiArg>({
      query: (queryArg) => ({ url: `/api/users/exerciseInstance/`, params: { page: queryArg.page } }),
    }),
    usersExerciseInstanceCreate: build.mutation<
      UsersExerciseInstanceCreateApiResponse,
      UsersExerciseInstanceCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/exerciseInstance/`,
        method: "POST",
        body: queryArg.exerciseInstanceCreateUpdateRequest,
      }),
    }),
    usersExerciseInstanceRetrieve: build.query<
      UsersExerciseInstanceRetrieveApiResponse,
      UsersExerciseInstanceRetrieveApiArg
    >({
      query: (queryArg) => ({ url: `/api/users/exerciseInstance/${queryArg.id}/` }),
    }),
    usersExerciseInstanceUpdate: build.mutation<
      UsersExerciseInstanceUpdateApiResponse,
      UsersExerciseInstanceUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/exerciseInstance/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.exerciseInstanceCreateUpdateRequest,
      }),
    }),
    usersExerciseInstancePartialUpdate: build.mutation<
      UsersExerciseInstancePartialUpdateApiResponse,
      UsersExerciseInstancePartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/exerciseInstance/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedExerciseInstanceCreateUpdateRequest,
      }),
    }),
    usersExerciseInstanceDestroy: build.mutation<
      UsersExerciseInstanceDestroyApiResponse,
      UsersExerciseInstanceDestroyApiArg
    >({
      query: (queryArg) => ({ url: `/api/users/exerciseInstance/${queryArg.id}/`, method: "DELETE" }),
    }),
    usersExerciseTemplateList: build.query<UsersExerciseTemplateListApiResponse, UsersExerciseTemplateListApiArg>({
      query: (queryArg) => ({ url: `/api/users/exerciseTemplate/`, params: { page: queryArg.page } }),
    }),
    usersExerciseTemplateRetrieve: build.query<
      UsersExerciseTemplateRetrieveApiResponse,
      UsersExerciseTemplateRetrieveApiArg
    >({
      query: (queryArg) => ({ url: `/api/users/exerciseTemplate/${queryArg.id}/` }),
    }),
    usersMealSlotSpecificationList: build.query<
      UsersMealSlotSpecificationListApiResponse,
      UsersMealSlotSpecificationListApiArg
    >({
      query: (queryArg) => ({ url: `/api/users/mealSlotSpecification/`, params: { page: queryArg.page } }),
    }),
    usersMealSlotSpecificationCreate: build.mutation<
      UsersMealSlotSpecificationCreateApiResponse,
      UsersMealSlotSpecificationCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/mealSlotSpecification/`,
        method: "POST",
        body: queryArg.mealSlotSpecificationRequest,
      }),
    }),
    usersMealSlotSpecificationRetrieve: build.query<
      UsersMealSlotSpecificationRetrieveApiResponse,
      UsersMealSlotSpecificationRetrieveApiArg
    >({
      query: (queryArg) => ({ url: `/api/users/mealSlotSpecification/${queryArg.id}/` }),
    }),
    usersMealSlotSpecificationUpdate: build.mutation<
      UsersMealSlotSpecificationUpdateApiResponse,
      UsersMealSlotSpecificationUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/mealSlotSpecification/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.mealSlotSpecificationRequest,
      }),
    }),
    usersMealSlotSpecificationPartialUpdate: build.mutation<
      UsersMealSlotSpecificationPartialUpdateApiResponse,
      UsersMealSlotSpecificationPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/mealSlotSpecification/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedMealSlotSpecificationRequest,
      }),
    }),
    usersMealSlotSpecificationDestroy: build.mutation<
      UsersMealSlotSpecificationDestroyApiResponse,
      UsersMealSlotSpecificationDestroyApiArg
    >({
      query: (queryArg) => ({ url: `/api/users/mealSlotSpecification/${queryArg.id}/`, method: "DELETE" }),
    }),
    usersNutritionDayPlanList: build.query<UsersNutritionDayPlanListApiResponse, UsersNutritionDayPlanListApiArg>({
      query: (queryArg) => ({ url: `/api/users/nutritionDayPlan/`, params: { page: queryArg.page } }),
    }),
    usersNutritionDayPlanCreate: build.mutation<
      UsersNutritionDayPlanCreateApiResponse,
      UsersNutritionDayPlanCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/nutritionDayPlan/`,
        method: "POST",
        body: queryArg.nutritionDayPlanRequest,
      }),
    }),
    usersNutritionDayPlanRetrieve: build.query<
      UsersNutritionDayPlanRetrieveApiResponse,
      UsersNutritionDayPlanRetrieveApiArg
    >({
      query: (queryArg) => ({ url: `/api/users/nutritionDayPlan/${queryArg.id}/` }),
    }),
    usersNutritionDayPlanUpdate: build.mutation<
      UsersNutritionDayPlanUpdateApiResponse,
      UsersNutritionDayPlanUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/nutritionDayPlan/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.nutritionDayPlanRequest,
      }),
    }),
    usersNutritionDayPlanPartialUpdate: build.mutation<
      UsersNutritionDayPlanPartialUpdateApiResponse,
      UsersNutritionDayPlanPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/nutritionDayPlan/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedNutritionDayPlanRequest,
      }),
    }),
    usersNutritionDayPlanDestroy: build.mutation<
      UsersNutritionDayPlanDestroyApiResponse,
      UsersNutritionDayPlanDestroyApiArg
    >({
      query: (queryArg) => ({ url: `/api/users/nutritionDayPlan/${queryArg.id}/`, method: "DELETE" }),
    }),
    usersOrganisationCreate: build.mutation<UsersOrganisationCreateApiResponse, UsersOrganisationCreateApiArg>({
      query: (queryArg) => ({
        url: `/api/users/organisation/`,
        method: "POST",
        body: queryArg.updateCreateOrganisationRequest,
      }),
    }),
    usersOrganisationPartialUpdate: build.mutation<
      UsersOrganisationPartialUpdateApiResponse,
      UsersOrganisationPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/organisation/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedUpdateCreateOrganisationRequest,
      }),
    }),
    usersPaginatedclientsList: build.query<UsersPaginatedclientsListApiResponse, UsersPaginatedclientsListApiArg>({
      query: (queryArg) => ({
        url: `/api/users/paginatedclients/`,
        params: { account_enabled: queryArg.accountEnabled, page: queryArg.page, search: queryArg.search },
      }),
    }),
    usersPaginatedclientsCreate: build.mutation<
      UsersPaginatedclientsCreateApiResponse,
      UsersPaginatedclientsCreateApiArg
    >({
      query: (queryArg) => ({ url: `/api/users/paginatedclients/`, method: "POST", body: queryArg.slimClientRequest }),
    }),
    usersPaginatedclientsRetrieve: build.query<
      UsersPaginatedclientsRetrieveApiResponse,
      UsersPaginatedclientsRetrieveApiArg
    >({
      query: (queryArg) => ({ url: `/api/users/paginatedclients/${queryArg.id}/` }),
    }),
    usersPaginatedclientsUpdate: build.mutation<
      UsersPaginatedclientsUpdateApiResponse,
      UsersPaginatedclientsUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/paginatedclients/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.slimClientRequest,
      }),
    }),
    usersPaginatedclientsPartialUpdate: build.mutation<
      UsersPaginatedclientsPartialUpdateApiResponse,
      UsersPaginatedclientsPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/paginatedclients/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedSlimClientRequest,
      }),
    }),
    usersPaginatedclientsDestroy: build.mutation<
      UsersPaginatedclientsDestroyApiResponse,
      UsersPaginatedclientsDestroyApiArg
    >({
      query: (queryArg) => ({ url: `/api/users/paginatedclients/${queryArg.id}/`, method: "DELETE" }),
    }),
    usersSetIngredientsToAvoidCreate: build.mutation<
      UsersSetIngredientsToAvoidCreateApiResponse,
      UsersSetIngredientsToAvoidCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/setIngredientsToAvoid/`,
        method: "POST",
        body: queryArg.setIngredientToAvoidRequest,
      }),
    }),
    usersStripeCustomerPortalCreate: build.mutation<
      UsersStripeCustomerPortalCreateApiResponse,
      UsersStripeCustomerPortalCreateApiArg
    >({
      query: () => ({ url: `/api/users/stripeCustomerPortal/`, method: "POST" }),
    }),
    usersUserList: build.query<UsersUserListApiResponse, UsersUserListApiArg>({
      query: (queryArg) => ({ url: `/api/users/user/`, params: { page: queryArg.page } }),
    }),
    usersUserCreate: build.mutation<UsersUserCreateApiResponse, UsersUserCreateApiArg>({
      query: (queryArg) => ({ url: `/api/users/user/`, method: "POST", body: queryArg.userRequest }),
    }),
    usersUserRetrieve: build.query<UsersUserRetrieveApiResponse, UsersUserRetrieveApiArg>({
      query: (queryArg) => ({ url: `/api/users/user/${queryArg.id}/` }),
    }),
    usersUserUpdate: build.mutation<UsersUserUpdateApiResponse, UsersUserUpdateApiArg>({
      query: (queryArg) => ({ url: `/api/users/user/${queryArg.id}/`, method: "PUT", body: queryArg.userRequest }),
    }),
    usersUserPartialUpdate: build.mutation<UsersUserPartialUpdateApiResponse, UsersUserPartialUpdateApiArg>({
      query: (queryArg) => ({
        url: `/api/users/user/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedUserRequest,
      }),
    }),
    usersUserDestroy: build.mutation<UsersUserDestroyApiResponse, UsersUserDestroyApiArg>({
      query: (queryArg) => ({ url: `/api/users/user/${queryArg.id}/`, method: "DELETE" }),
    }),
    usersUserProfileList: build.query<UsersUserProfileListApiResponse, UsersUserProfileListApiArg>({
      query: (queryArg) => ({ url: `/api/users/userProfile/`, params: { page: queryArg.page } }),
    }),
    usersUserProfileCreate: build.mutation<UsersUserProfileCreateApiResponse, UsersUserProfileCreateApiArg>({
      query: (queryArg) => ({ url: `/api/users/userProfile/`, method: "POST", body: queryArg.userProfileRequest }),
    }),
    usersUserProfileRetrieve: build.query<UsersUserProfileRetrieveApiResponse, UsersUserProfileRetrieveApiArg>({
      query: (queryArg) => ({ url: `/api/users/userProfile/${queryArg.id}/` }),
    }),
    usersUserProfileUpdate: build.mutation<UsersUserProfileUpdateApiResponse, UsersUserProfileUpdateApiArg>({
      query: (queryArg) => ({
        url: `/api/users/userProfile/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.userProfileRequest,
      }),
    }),
    usersUserProfilePartialUpdate: build.mutation<
      UsersUserProfilePartialUpdateApiResponse,
      UsersUserProfilePartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/userProfile/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedUserProfileRequest,
      }),
    }),
    usersUserProfileDestroy: build.mutation<UsersUserProfileDestroyApiResponse, UsersUserProfileDestroyApiArg>({
      query: (queryArg) => ({ url: `/api/users/userProfile/${queryArg.id}/`, method: "DELETE" }),
    }),
    usersUserdislikerecipeList: build.query<UsersUserdislikerecipeListApiResponse, UsersUserdislikerecipeListApiArg>({
      query: (queryArg) => ({
        url: `/api/users/userdislikerecipe/`,
        params: { page: queryArg.page, recipe: queryArg.recipe, user: queryArg.user },
      }),
    }),
    usersUserdislikerecipeCreate: build.mutation<
      UsersUserdislikerecipeCreateApiResponse,
      UsersUserdislikerecipeCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/userdislikerecipe/`,
        method: "POST",
        body: queryArg.userDislikeRecipeRequest,
      }),
    }),
    usersUserdislikerecipeRetrieve: build.query<
      UsersUserdislikerecipeRetrieveApiResponse,
      UsersUserdislikerecipeRetrieveApiArg
    >({
      query: (queryArg) => ({ url: `/api/users/userdislikerecipe/${queryArg.id}/` }),
    }),
    usersUserdislikerecipeUpdate: build.mutation<
      UsersUserdislikerecipeUpdateApiResponse,
      UsersUserdislikerecipeUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/userdislikerecipe/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.userDislikeRecipeRequest,
      }),
    }),
    usersUserdislikerecipePartialUpdate: build.mutation<
      UsersUserdislikerecipePartialUpdateApiResponse,
      UsersUserdislikerecipePartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/userdislikerecipe/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedUserDislikeRecipeRequest,
      }),
    }),
    usersUserdislikerecipeDestroy: build.mutation<
      UsersUserdislikerecipeDestroyApiResponse,
      UsersUserdislikerecipeDestroyApiArg
    >({
      query: (queryArg) => ({ url: `/api/users/userdislikerecipe/${queryArg.id}/`, method: "DELETE" }),
    }),
    usersUserfavouriterecipeList: build.query<
      UsersUserfavouriterecipeListApiResponse,
      UsersUserfavouriterecipeListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/userfavouriterecipe/`,
        params: { page: queryArg.page, recipe: queryArg.recipe, user: queryArg.user },
      }),
    }),
    usersUserfavouriterecipeCreate: build.mutation<
      UsersUserfavouriterecipeCreateApiResponse,
      UsersUserfavouriterecipeCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/userfavouriterecipe/`,
        method: "POST",
        body: queryArg.userFavouriteRecipeRequest,
      }),
    }),
    usersUserfavouriterecipeRetrieve: build.query<
      UsersUserfavouriterecipeRetrieveApiResponse,
      UsersUserfavouriterecipeRetrieveApiArg
    >({
      query: (queryArg) => ({ url: `/api/users/userfavouriterecipe/${queryArg.id}/` }),
    }),
    usersUserfavouriterecipeUpdate: build.mutation<
      UsersUserfavouriterecipeUpdateApiResponse,
      UsersUserfavouriterecipeUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/userfavouriterecipe/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.userFavouriteRecipeRequest,
      }),
    }),
    usersUserfavouriterecipePartialUpdate: build.mutation<
      UsersUserfavouriterecipePartialUpdateApiResponse,
      UsersUserfavouriterecipePartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/userfavouriterecipe/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedUserFavouriteRecipeRequest,
      }),
    }),
    usersUserfavouriterecipeDestroy: build.mutation<
      UsersUserfavouriterecipeDestroyApiResponse,
      UsersUserfavouriterecipeDestroyApiArg
    >({
      query: (queryArg) => ({ url: `/api/users/userfavouriterecipe/${queryArg.id}/`, method: "DELETE" }),
    }),
    usersVerifyOtpCreate: build.mutation<UsersVerifyOtpCreateApiResponse, UsersVerifyOtpCreateApiArg>({
      query: (queryArg) => ({ url: `/api/users/verifyOTP/`, method: "POST", body: queryArg.verifyOtpRequest }),
    }),
    usersWeeklyNutritionPlanList: build.query<
      UsersWeeklyNutritionPlanListApiResponse,
      UsersWeeklyNutritionPlanListApiArg
    >({
      query: (queryArg) => ({ url: `/api/users/weeklyNutritionPlan/`, params: { page: queryArg.page } }),
    }),
    usersWeeklyNutritionPlanCreate: build.mutation<
      UsersWeeklyNutritionPlanCreateApiResponse,
      UsersWeeklyNutritionPlanCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/weeklyNutritionPlan/`,
        method: "POST",
        body: queryArg.weeklyNutritionPlanCreateRequest,
      }),
    }),
    usersWeeklyNutritionPlanRetrieve: build.query<
      UsersWeeklyNutritionPlanRetrieveApiResponse,
      UsersWeeklyNutritionPlanRetrieveApiArg
    >({
      query: (queryArg) => ({ url: `/api/users/weeklyNutritionPlan/${queryArg.id}/` }),
    }),
    usersWeeklyNutritionPlanUpdate: build.mutation<
      UsersWeeklyNutritionPlanUpdateApiResponse,
      UsersWeeklyNutritionPlanUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/weeklyNutritionPlan/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.weeklyNutritionPlanUpdateRequest,
      }),
    }),
    v2SchemaRetrieve: build.query<V2SchemaRetrieveApiResponse, V2SchemaRetrieveApiArg>({
      query: (queryArg) => ({ url: `/api/v2/schema/`, params: { format: queryArg.format, lang: queryArg.lang } }),
    }),
    v3SchemaRetrieve: build.query<V3SchemaRetrieveApiResponse, V3SchemaRetrieveApiArg>({
      query: (queryArg) => ({ url: `/api/v3/schema/`, params: { format: queryArg.format, lang: queryArg.lang } }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as backendApi };
export type CheckoutSubscriptionVerificationCreateApiResponse = /** status 200 Subscription verification response */ {
  [key: string]: any;
};
export type CheckoutSubscriptionVerificationCreateApiArg = {
  subscriptionVerificationRequest: SubscriptionVerificationRequest;
};
export type FoodCreateRecipeCreateApiResponse = /** status 201  */ RecipeTemplate;
export type FoodCreateRecipeCreateApiArg = {
  createNewRecipeTemplateRequest: CreateNewRecipeTemplateRequest;
};
export type FoodFoodListApiResponse = /** status 200  */ PaginatedFoodList;
export type FoodFoodListApiArg = {
  /** A page number within the paginated result set. */
  page?: number;
};
export type FoodFoodRetrieveApiResponse = /** status 200  */ Food;
export type FoodFoodRetrieveApiArg = {
  /** A unique integer value identifying this food. */
  id: number;
};
export type FoodGenerateMealsListApiResponse = /** status 200  */ PaginatedGeneratedRecipeMealList;
export type FoodGenerateMealsListApiArg = {
  author?: number;
  favourite?: boolean;
  ingredientSearch?: string;
  kcal?: number;
  mealSlotSpecification: number;
  /** * `BREAKFAST` - BREAKFAST
   * `LUNCH` - LUNCH
   * `DINNER` - DINNER
   * `SNACK` - SNACK */
  mealTypes?: ("BREAKFAST" | "DINNER" | "LUNCH" | "SNACK")[];
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  pageSize?: number;
  preparationTimeMinGte?: number;
  preparationTimeMinLte?: number;
  protein?: number;
  /** * `WEEKMEALS` - WEEKMEALS
   * `FATSECRET` - FATSECRET
   * `USER_GENERATED` - USER_GENERATED
   * `BRANDED` - BRANDED */
  sourceProvider?: ("BRANDED" | "FATSECRET" | "USER_GENERATED" | "WEEKMEALS")[];
  /** * `ROOT_NODE` - ROOT_NODE
   * `BREAD` - BREAD
   * `BREAKFAST` - BREAKFAST
   * `BURGER` - BURGER
   * `COUSCOUS_AND_QUINOA` - COUSCOUS_AND_QUINOA
   * `DAIRY` - DAIRY
   * `DINNER` - DINNER
   * `DIPS_AND_SPREADS` - DIPS_AND_SPREADS
   * `FAVOURITE` - FAVOURITE
   * `KETO` - KETO
   * `LOW_VARIANTS` - LOW_VARIANTS
   * `LUNCH` - LUNCH
   * `PANCAKES_AND_OMELETTES` - PANCAKES_AND_OMELETTES
   * `PASTA` - PASTA
   * `POTATO` - POTATO
   * `PRE_BED` - PRE_BED
   * `RICE_AND_NOODLES` - RICE_AND_NOODLES
   * `SALAD` - SALAD
   * `SNACK` - SNACK
   * `SNACKS_AND_BAKES` - SNACKS_AND_BAKES
   * `SOUP` - SOUP
   * `VEGAN` - VEGAN
   * `WRAP` - WRAP
   * `CRUSTACEANS_AND_SHELLFISH` - CRUSTACEANS_AND_SHELLFISH
   * `HALAL` - HALAL
   * `OTHER` - OTHER */
  tags?: (
    | "BREAD"
    | "BREAKFAST"
    | "BURGER"
    | "COUSCOUS_AND_QUINOA"
    | "CRUSTACEANS_AND_SHELLFISH"
    | "DAIRY"
    | "DINNER"
    | "DIPS_AND_SPREADS"
    | "FAVOURITE"
    | "HALAL"
    | "KETO"
    | "LOW_VARIANTS"
    | "LUNCH"
    | "OTHER"
    | "PANCAKES_AND_OMELETTES"
    | "PASTA"
    | "POTATO"
    | "PRE_BED"
    | "RICE_AND_NOODLES"
    | "ROOT_NODE"
    | "SALAD"
    | "SNACK"
    | "SNACKS_AND_BAKES"
    | "SOUP"
    | "VEGAN"
    | "WRAP"
  )[];
  user?: number;
};
export type FoodGenerateMealsCreateApiResponse = /** status 201  */ RecipeMeal;
export type FoodGenerateMealsCreateApiArg = {
  generatedRecipeMealRequest: GeneratedRecipeMealRequest;
};
export type FoodIngredientListApiResponse = /** status 200  */ PaginatedIngredientList;
export type FoodIngredientListApiArg = {
  name?: string;
  /** A page number within the paginated result set. */
  page?: number;
};
export type FoodIngredientCreateApiResponse = /** status 201  */ IngredientPost;
export type FoodIngredientCreateApiArg = {
  ingredientPostRequest: IngredientPostRequest;
};
export type FoodIngredientRetrieveApiResponse = /** status 200  */ Ingredient;
export type FoodIngredientRetrieveApiArg = {
  /** A unique integer value identifying this ingredient. */
  id: number;
};
export type FoodIngredientUpdateApiResponse = /** status 200  */ Ingredient;
export type FoodIngredientUpdateApiArg = {
  /** A unique integer value identifying this ingredient. */
  id: number;
  ingredientRequest: IngredientRequest;
};
export type FoodIngredientPartialUpdateApiResponse = /** status 200  */ Ingredient;
export type FoodIngredientPartialUpdateApiArg = {
  /** A unique integer value identifying this ingredient. */
  id: number;
  patchedIngredientRequest: PatchedIngredientRequest;
};
export type FoodIngredientDestroyApiResponse = unknown;
export type FoodIngredientDestroyApiArg = {
  /** A unique integer value identifying this ingredient. */
  id: number;
};
export type FoodIngredientToAvoidListApiResponse = /** status 200  */ PaginatedIngredientToAvoidList;
export type FoodIngredientToAvoidListApiArg = {
  /** A page number within the paginated result set. */
  page?: number;
};
export type FoodIngredientToAvoidRetrieveApiResponse = /** status 200  */ IngredientToAvoid;
export type FoodIngredientToAvoidRetrieveApiArg = {
  /** A UUID string identifying this Ingredient to Avoid. */
  id: string;
};
export type FoodPreferredSuggestedServingsListApiResponse = /** status 200  */ PaginatedPreferredSuggestedServingList;
export type FoodPreferredSuggestedServingsListApiArg = {
  organisationId?: number;
  /** A page number within the paginated result set. */
  page?: number;
  query?: string;
};
export type FoodPreferredSuggestedServingsCreateApiResponse = /** status 201  */ PreferredSuggestedServing;
export type FoodPreferredSuggestedServingsCreateApiArg = {
  preferredSuggestedServingRequest: PreferredSuggestedServingRequest;
};
export type FoodPreferredSuggestedServingsRetrieveApiResponse = /** status 200  */ PreferredSuggestedServing;
export type FoodPreferredSuggestedServingsRetrieveApiArg = {
  /** A unique integer value identifying this preferred suggested serving. */
  id: number;
};
export type FoodPreferredSuggestedServingsUpdateApiResponse = /** status 200  */ PreferredSuggestedServing;
export type FoodPreferredSuggestedServingsUpdateApiArg = {
  /** A unique integer value identifying this preferred suggested serving. */
  id: number;
  preferredSuggestedServingRequest: PreferredSuggestedServingRequest;
};
export type FoodPreferredSuggestedServingsPartialUpdateApiResponse = /** status 200  */ PreferredSuggestedServing;
export type FoodPreferredSuggestedServingsPartialUpdateApiArg = {
  /** A unique integer value identifying this preferred suggested serving. */
  id: number;
  patchedPreferredSuggestedServingRequest: PatchedPreferredSuggestedServingRequest;
};
export type FoodPreferredSuggestedServingsDestroyApiResponse = unknown;
export type FoodPreferredSuggestedServingsDestroyApiArg = {
  /** A unique integer value identifying this preferred suggested serving. */
  id: number;
};
export type FoodQuickAddMealListApiResponse = /** status 200  */ PaginatedQuickAddMealList;
export type FoodQuickAddMealListApiArg = {
  /** A page number within the paginated result set. */
  page?: number;
};
export type FoodQuickAddMealCreateApiResponse = /** status 201  */ QuickAddMeal;
export type FoodQuickAddMealCreateApiArg = {
  quickAddMealRequest: QuickAddMealRequest;
};
export type FoodQuickAddMealRetrieveApiResponse = /** status 200  */ QuickAddMeal;
export type FoodQuickAddMealRetrieveApiArg = {
  /** A unique integer value identifying this quick add meal. */
  id: number;
};
export type FoodQuickAddMealUpdateApiResponse = /** status 200  */ QuickAddMeal;
export type FoodQuickAddMealUpdateApiArg = {
  /** A unique integer value identifying this quick add meal. */
  id: number;
  quickAddMealRequest: QuickAddMealRequest;
};
export type FoodQuickAddMealPartialUpdateApiResponse = /** status 200  */ QuickAddMeal;
export type FoodQuickAddMealPartialUpdateApiArg = {
  /** A unique integer value identifying this quick add meal. */
  id: number;
  patchedQuickAddMealRequest: PatchedQuickAddMealRequest;
};
export type FoodQuickAddMealDestroyApiResponse = unknown;
export type FoodQuickAddMealDestroyApiArg = {
  /** A unique integer value identifying this quick add meal. */
  id: number;
};
export type FoodRecipeFeedbackLineItemListApiResponse = /** status 200  */ PaginatedRecipeFeedbackLineItemList;
export type FoodRecipeFeedbackLineItemListApiArg = {
  /** A page number within the paginated result set. */
  page?: number;
};
export type FoodRecipeFeedbackLineItemCreateApiResponse = /** status 201  */ RecipeFeedbackLineItem;
export type FoodRecipeFeedbackLineItemCreateApiArg = {
  recipeFeedbackLineItemRequest: RecipeFeedbackLineItemRequest;
};
export type FoodRecipeFeedbackLineItemRetrieveApiResponse = /** status 200  */ RecipeFeedbackLineItem;
export type FoodRecipeFeedbackLineItemRetrieveApiArg = {
  /** A UUID string identifying this Recipe Feedback Line Item. */
  id: string;
};
export type FoodRecipeFeedbackLineItemUpdateApiResponse = /** status 200  */ RecipeFeedbackLineItem;
export type FoodRecipeFeedbackLineItemUpdateApiArg = {
  /** A UUID string identifying this Recipe Feedback Line Item. */
  id: string;
  recipeFeedbackLineItemRequest: RecipeFeedbackLineItemRequest;
};
export type FoodRecipeFeedbackLineItemPartialUpdateApiResponse = /** status 200  */ RecipeFeedbackLineItem;
export type FoodRecipeFeedbackLineItemPartialUpdateApiArg = {
  /** A UUID string identifying this Recipe Feedback Line Item. */
  id: string;
  patchedRecipeFeedbackLineItemRequest: PatchedRecipeFeedbackLineItemRequest;
};
export type FoodRecipeFeedbackLineItemDestroyApiResponse = unknown;
export type FoodRecipeFeedbackLineItemDestroyApiArg = {
  /** A UUID string identifying this Recipe Feedback Line Item. */
  id: string;
};
export type FoodRecipeMealListApiResponse = /** status 200  */ PaginatedRecipeMealList;
export type FoodRecipeMealListApiArg = {
  /** A page number within the paginated result set. */
  page?: number;
};
export type FoodRecipeMealCreateApiResponse = /** status 201  */ RecipeMeal;
export type FoodRecipeMealCreateApiArg = {
  recipeMealRequest: RecipeMealRequest;
};
export type FoodRecipeMealRetrieveApiResponse = /** status 200  */ RecipeMeal;
export type FoodRecipeMealRetrieveApiArg = {
  /** A unique integer value identifying this recipe meal. */
  id: number;
};
export type FoodRecipeMealUpdateApiResponse = /** status 200  */ RecipeMeal;
export type FoodRecipeMealUpdateApiArg = {
  /** A unique integer value identifying this recipe meal. */
  id: number;
  recipeMealRequest: RecipeMealRequest;
};
export type FoodRecipeMealPartialUpdateApiResponse = /** status 200  */ RecipeMeal;
export type FoodRecipeMealPartialUpdateApiArg = {
  /** A unique integer value identifying this recipe meal. */
  id: number;
  patchedRecipeMealRequest: PatchedRecipeMealRequest;
};
export type FoodRecipeMealDestroyApiResponse = unknown;
export type FoodRecipeMealDestroyApiArg = {
  /** A unique integer value identifying this recipe meal. */
  id: number;
};
export type FoodRecipeTagListApiResponse = /** status 200  */ PaginatedRecipeTagList;
export type FoodRecipeTagListApiArg = {
  /** A page number within the paginated result set. */
  page?: number;
};
export type FoodRecipeTagRetrieveApiResponse = /** status 200  */ RecipeTag;
export type FoodRecipeTagRetrieveApiArg = {
  /** A unique integer value identifying this recipe tag. */
  id: number;
};
export type FoodRecipeTemplateListApiResponse = /** status 200  */ PaginatedRecipeTemplateList;
export type FoodRecipeTemplateListApiArg = {
  author?: number;
  favourite?: boolean;
  ingredientSearch?: string;
  /** * `BREAKFAST` - BREAKFAST
   * `LUNCH` - LUNCH
   * `DINNER` - DINNER
   * `SNACK` - SNACK */
  mealTypes?: ("BREAKFAST" | "DINNER" | "LUNCH" | "SNACK")[];
  /** A page number within the paginated result set. */
  page?: number;
  preparationTimeMinGte?: number;
  preparationTimeMinLte?: number;
  /** * `WEEKMEALS` - WEEKMEALS
   * `FATSECRET` - FATSECRET
   * `USER_GENERATED` - USER_GENERATED
   * `BRANDED` - BRANDED */
  sourceProvider?: ("BRANDED" | "FATSECRET" | "USER_GENERATED" | "WEEKMEALS")[];
  /** * `ROOT_NODE` - ROOT_NODE
   * `BREAD` - BREAD
   * `BREAKFAST` - BREAKFAST
   * `BURGER` - BURGER
   * `COUSCOUS_AND_QUINOA` - COUSCOUS_AND_QUINOA
   * `DAIRY` - DAIRY
   * `DINNER` - DINNER
   * `DIPS_AND_SPREADS` - DIPS_AND_SPREADS
   * `FAVOURITE` - FAVOURITE
   * `KETO` - KETO
   * `LOW_VARIANTS` - LOW_VARIANTS
   * `LUNCH` - LUNCH
   * `PANCAKES_AND_OMELETTES` - PANCAKES_AND_OMELETTES
   * `PASTA` - PASTA
   * `POTATO` - POTATO
   * `PRE_BED` - PRE_BED
   * `RICE_AND_NOODLES` - RICE_AND_NOODLES
   * `SALAD` - SALAD
   * `SNACK` - SNACK
   * `SNACKS_AND_BAKES` - SNACKS_AND_BAKES
   * `SOUP` - SOUP
   * `VEGAN` - VEGAN
   * `WRAP` - WRAP
   * `CRUSTACEANS_AND_SHELLFISH` - CRUSTACEANS_AND_SHELLFISH
   * `HALAL` - HALAL
   * `OTHER` - OTHER */
  tags?: (
    | "BREAD"
    | "BREAKFAST"
    | "BURGER"
    | "COUSCOUS_AND_QUINOA"
    | "CRUSTACEANS_AND_SHELLFISH"
    | "DAIRY"
    | "DINNER"
    | "DIPS_AND_SPREADS"
    | "FAVOURITE"
    | "HALAL"
    | "KETO"
    | "LOW_VARIANTS"
    | "LUNCH"
    | "OTHER"
    | "PANCAKES_AND_OMELETTES"
    | "PASTA"
    | "POTATO"
    | "PRE_BED"
    | "RICE_AND_NOODLES"
    | "ROOT_NODE"
    | "SALAD"
    | "SNACK"
    | "SNACKS_AND_BAKES"
    | "SOUP"
    | "VEGAN"
    | "WRAP"
  )[];
  user?: number;
};
export type FoodRecipeTemplateCreateApiResponse = /** status 201  */ RecipeTemplate;
export type FoodRecipeTemplateCreateApiArg = {
  recipeTemplateRequest: RecipeTemplateRequest;
};
export type FoodRecipeTemplateRetrieveApiResponse = /** status 200  */ RecipeTemplate;
export type FoodRecipeTemplateRetrieveApiArg = {
  /** A unique integer value identifying this recipe template. */
  id: number;
};
export type FoodRecipeTemplateUpdateApiResponse = /** status 200  */ RecipeTemplate;
export type FoodRecipeTemplateUpdateApiArg = {
  /** A unique integer value identifying this recipe template. */
  id: number;
  recipeTemplateRequest: RecipeTemplateRequest;
};
export type FoodRecipeTemplatePartialUpdateApiResponse = /** status 200  */ RecipeTemplate;
export type FoodRecipeTemplatePartialUpdateApiArg = {
  /** A unique integer value identifying this recipe template. */
  id: number;
  patchedRecipeTemplateRequest: PatchedRecipeTemplateRequest;
};
export type FoodRecipeTemplateDestroyApiResponse = unknown;
export type FoodRecipeTemplateDestroyApiArg = {
  /** A unique integer value identifying this recipe template. */
  id: number;
};
export type FoodSearchListApiResponse = /** status 200  */ Food[];
export type FoodSearchListApiArg = {
  /** Query string */
  query: string;
};
export type FoodSearchAutocompleteRetrieveApiResponse = /** status 200  */ FoodSearchAutoComplete;
export type FoodSearchAutocompleteRetrieveApiArg = {
  /** Query string */
  query: string;
};
export type FoodSearchByBarcodeRetrieveApiResponse = /** status 200  */ Food;
export type FoodSearchByBarcodeRetrieveApiArg = {
  /** UPC-A, EAN-13 or EAN-8 formatted barcode */
  barcode: string;
};
export type FoodSingleFoodMealListApiResponse = /** status 200  */ PaginatedSingleFoodMealList;
export type FoodSingleFoodMealListApiArg = {
  /** A page number within the paginated result set. */
  page?: number;
};
export type FoodSingleFoodMealCreateApiResponse = /** status 201  */ SingleFoodMeal;
export type FoodSingleFoodMealCreateApiArg = {
  singleFoodMealRequest: SingleFoodMealRequest;
};
export type FoodSingleFoodMealRetrieveApiResponse = /** status 200  */ SingleFoodMeal;
export type FoodSingleFoodMealRetrieveApiArg = {
  /** A unique integer value identifying this single food meal. */
  id: number;
};
export type FoodSingleFoodMealUpdateApiResponse = /** status 200  */ SingleFoodMeal;
export type FoodSingleFoodMealUpdateApiArg = {
  /** A unique integer value identifying this single food meal. */
  id: number;
  singleFoodMealRequest: SingleFoodMealRequest;
};
export type FoodSingleFoodMealPartialUpdateApiResponse = /** status 200  */ SingleFoodMeal;
export type FoodSingleFoodMealPartialUpdateApiArg = {
  /** A unique integer value identifying this single food meal. */
  id: number;
  patchedSingleFoodMealRequest: PatchedSingleFoodMealRequest;
};
export type FoodSingleFoodMealDestroyApiResponse = unknown;
export type FoodSingleFoodMealDestroyApiArg = {
  /** A unique integer value identifying this single food meal. */
  id: number;
};
export type FoodSuggestedServingListApiResponse = /** status 200  */ PaginatedSuggestedServingList;
export type FoodSuggestedServingListApiArg = {
  /** A page number within the paginated result set. */
  page?: number;
};
export type FoodSuggestedServingRetrieveApiResponse = /** status 200  */ SuggestedServing;
export type FoodSuggestedServingRetrieveApiArg = {
  /** A unique integer value identifying this suggested serving. */
  id: number;
};
export type FoodSuggestedServingSearchListApiResponse = /** status 200  */ PaginatedSuggestedServingSearchList;
export type FoodSuggestedServingSearchListApiArg = {
  /** * `WEEKMEALS` - WEEKMEALS
   * `FATSECRET` - FATSECRET
   * `USER_GENERATED` - USER_GENERATED
   * `BRANDED` - BRANDED */
  foodSourceProvider?: "BRANDED" | "FATSECRET" | "USER_GENERATED" | "WEEKMEALS";
  /** A page number within the paginated result set. */
  page?: number;
  /** query */
  query?: string;
};
export type FoodSuggestedServingSearchRetrieveApiResponse = /** status 200  */ SuggestedServingSearch;
export type FoodSuggestedServingSearchRetrieveApiArg = {
  /** A unique integer value identifying this suggested serving. */
  id: number;
};
export type MeasurementsUserBodyFatPercentageListApiResponse = /** status 200  */ PaginatedUserBodyFatPercentageList;
export type MeasurementsUserBodyFatPercentageListApiArg = {
  /** A page number within the paginated result set. */
  page?: number;
  user?: number;
};
export type MeasurementsUserBodyFatPercentageCreateApiResponse = /** status 201  */ UserBodyFatPercentage;
export type MeasurementsUserBodyFatPercentageCreateApiArg = {
  userBodyFatPercentageRequest: UserBodyFatPercentageRequest;
};
export type MeasurementsUserBodyFatPercentageRetrieveApiResponse = /** status 200  */ UserBodyFatPercentage;
export type MeasurementsUserBodyFatPercentageRetrieveApiArg = {
  /** A unique integer value identifying this user body fat percentage. */
  id: number;
};
export type MeasurementsUserBodyFatPercentageUpdateApiResponse = /** status 200  */ UserBodyFatPercentage;
export type MeasurementsUserBodyFatPercentageUpdateApiArg = {
  /** A unique integer value identifying this user body fat percentage. */
  id: number;
  userBodyFatPercentageRequest: UserBodyFatPercentageRequest;
};
export type MeasurementsUserBodyFatPercentagePartialUpdateApiResponse = /** status 200  */ UserBodyFatPercentage;
export type MeasurementsUserBodyFatPercentagePartialUpdateApiArg = {
  /** A unique integer value identifying this user body fat percentage. */
  id: number;
  patchedUserBodyFatPercentageRequest: PatchedUserBodyFatPercentageRequest;
};
export type MeasurementsUserBodyFatPercentageDestroyApiResponse = unknown;
export type MeasurementsUserBodyFatPercentageDestroyApiArg = {
  /** A unique integer value identifying this user body fat percentage. */
  id: number;
};
export type MeasurementsUserDistanceListApiResponse = /** status 200  */ PaginatedUserDistanceList;
export type MeasurementsUserDistanceListApiArg = {
  /** A page number within the paginated result set. */
  page?: number;
  user?: number;
};
export type MeasurementsUserDistanceCreateApiResponse = /** status 201  */ UserDistance;
export type MeasurementsUserDistanceCreateApiArg = {
  userDistanceRequest: UserDistanceRequest;
};
export type MeasurementsUserDistanceRetrieveApiResponse = /** status 200  */ UserDistance;
export type MeasurementsUserDistanceRetrieveApiArg = {
  /** A unique integer value identifying this user distance. */
  id: number;
};
export type MeasurementsUserDistanceUpdateApiResponse = /** status 200  */ UserDistance;
export type MeasurementsUserDistanceUpdateApiArg = {
  /** A unique integer value identifying this user distance. */
  id: number;
  userDistanceRequest: UserDistanceRequest;
};
export type MeasurementsUserDistancePartialUpdateApiResponse = /** status 200  */ UserDistance;
export type MeasurementsUserDistancePartialUpdateApiArg = {
  /** A unique integer value identifying this user distance. */
  id: number;
  patchedUserDistanceRequest: PatchedUserDistanceRequest;
};
export type MeasurementsUserDistanceDestroyApiResponse = unknown;
export type MeasurementsUserDistanceDestroyApiArg = {
  /** A unique integer value identifying this user distance. */
  id: number;
};
export type MeasurementsUserSleepListApiResponse = /** status 200  */ PaginatedUserSleepList;
export type MeasurementsUserSleepListApiArg = {
  /** A page number within the paginated result set. */
  page?: number;
  user?: number;
};
export type MeasurementsUserSleepCreateApiResponse = /** status 201  */ UserSleep;
export type MeasurementsUserSleepCreateApiArg = {
  userSleepRequest: UserSleepRequest;
};
export type MeasurementsUserSleepRetrieveApiResponse = /** status 200  */ UserSleep;
export type MeasurementsUserSleepRetrieveApiArg = {
  /** A unique integer value identifying this user sleep. */
  id: number;
};
export type MeasurementsUserSleepUpdateApiResponse = /** status 200  */ UserSleep;
export type MeasurementsUserSleepUpdateApiArg = {
  /** A unique integer value identifying this user sleep. */
  id: number;
  userSleepRequest: UserSleepRequest;
};
export type MeasurementsUserSleepPartialUpdateApiResponse = /** status 200  */ UserSleep;
export type MeasurementsUserSleepPartialUpdateApiArg = {
  /** A unique integer value identifying this user sleep. */
  id: number;
  patchedUserSleepRequest: PatchedUserSleepRequest;
};
export type MeasurementsUserSleepDestroyApiResponse = unknown;
export type MeasurementsUserSleepDestroyApiArg = {
  /** A unique integer value identifying this user sleep. */
  id: number;
};
export type MeasurementsUserStressListApiResponse = /** status 200  */ PaginatedUserStressList;
export type MeasurementsUserStressListApiArg = {
  /** A page number within the paginated result set. */
  page?: number;
  user?: number;
};
export type MeasurementsUserStressCreateApiResponse = /** status 201  */ UserStress;
export type MeasurementsUserStressCreateApiArg = {
  userStressRequest: UserStressRequest;
};
export type MeasurementsUserStressRetrieveApiResponse = /** status 200  */ UserStress;
export type MeasurementsUserStressRetrieveApiArg = {
  /** A unique integer value identifying this user stress. */
  id: number;
};
export type MeasurementsUserStressUpdateApiResponse = /** status 200  */ UserStress;
export type MeasurementsUserStressUpdateApiArg = {
  /** A unique integer value identifying this user stress. */
  id: number;
  userStressRequest: UserStressRequest;
};
export type MeasurementsUserStressPartialUpdateApiResponse = /** status 200  */ UserStress;
export type MeasurementsUserStressPartialUpdateApiArg = {
  /** A unique integer value identifying this user stress. */
  id: number;
  patchedUserStressRequest: PatchedUserStressRequest;
};
export type MeasurementsUserStressDestroyApiResponse = unknown;
export type MeasurementsUserStressDestroyApiArg = {
  /** A unique integer value identifying this user stress. */
  id: number;
};
export type MeasurementsDeleteMeasurementCreateApiResponse = /** status 201  */ DeleteMeasurement;
export type MeasurementsDeleteMeasurementCreateApiArg = {
  deleteMeasurementRequest: DeleteMeasurementRequest;
};
export type MeasurementsUserWeightListApiResponse = /** status 200  */ PaginatedUserWeightList;
export type MeasurementsUserWeightListApiArg = {
  /** A page number within the paginated result set. */
  page?: number;
  user?: number;
};
export type MeasurementsUserWeightCreateApiResponse = /** status 201  */ UserWeight;
export type MeasurementsUserWeightCreateApiArg = {
  userWeightRequest: UserWeightRequest;
};
export type MeasurementsUserWeightRetrieveApiResponse = /** status 200  */ UserWeight;
export type MeasurementsUserWeightRetrieveApiArg = {
  /** A unique integer value identifying this user weight. */
  id: number;
};
export type MeasurementsUserWeightUpdateApiResponse = /** status 200  */ UserWeight;
export type MeasurementsUserWeightUpdateApiArg = {
  /** A unique integer value identifying this user weight. */
  id: number;
  userWeightRequest: UserWeightRequest;
};
export type MeasurementsUserWeightPartialUpdateApiResponse = /** status 200  */ UserWeight;
export type MeasurementsUserWeightPartialUpdateApiArg = {
  /** A unique integer value identifying this user weight. */
  id: number;
  patchedUserWeightRequest: PatchedUserWeightRequest;
};
export type MeasurementsUserWeightDestroyApiResponse = unknown;
export type MeasurementsUserWeightDestroyApiArg = {
  /** A unique integer value identifying this user weight. */
  id: number;
};
export type PlannerAutoPlanDayCreateApiResponse = unknown;
export type PlannerAutoPlanDayCreateApiArg = {
  autoPlannerRequest: AutoPlannerRequest;
};
export type PlannerAutoPlanDaysCreateApiResponse = unknown;
export type PlannerAutoPlanDaysCreateApiArg = {
  autoPlanDaysRequest: AutoPlanDaysRequest;
};
export type PlannerCalendarDayListApiResponse = /** status 200  */ PaginatedCalendarDayList;
export type PlannerCalendarDayListApiArg = {
  dateGte?: string;
  dateLt?: string;
  dateLte?: string;
  /** A page number within the paginated result set. */
  page?: number;
  user?: number;
};
export type PlannerCalendarDayCreateApiResponse = /** status 201  */ CalendarDay;
export type PlannerCalendarDayCreateApiArg = {
  calendarDayRequest: CalendarDayRequest;
};
export type PlannerCalendarDayRetrieveApiResponse = /** status 200  */ CalendarDay;
export type PlannerCalendarDayRetrieveApiArg = {
  /** A unique integer value identifying this calendar day. */
  id: number;
};
export type PlannerCalendarDayUpdateApiResponse = /** status 200  */ CalendarDay;
export type PlannerCalendarDayUpdateApiArg = {
  /** A unique integer value identifying this calendar day. */
  id: number;
  calendarDayRequest: CalendarDayRequest;
};
export type PlannerCalendarDayPartialUpdateApiResponse = /** status 200  */ CalendarDay;
export type PlannerCalendarDayPartialUpdateApiArg = {
  /** A unique integer value identifying this calendar day. */
  id: number;
  patchedCalendarDayRequest: PatchedCalendarDayRequest;
};
export type PlannerCalendarDayDestroyApiResponse = unknown;
export type PlannerCalendarDayDestroyApiArg = {
  /** A unique integer value identifying this calendar day. */
  id: number;
};
export type PlannerCalendarItemListApiResponse = /** status 200  */ PaginatedCalendarItemList;
export type PlannerCalendarItemListApiArg = {
  calendarDayUser?: number;
  /** A page number within the paginated result set. */
  page?: number;
};
export type PlannerCalendarItemCreateApiResponse = /** status 201  */ CalendarItem;
export type PlannerCalendarItemCreateApiArg = {
  calendarItemRequest: CalendarItemRequest;
};
export type PlannerCalendarItemRetrieveApiResponse = /** status 200  */ CalendarItem;
export type PlannerCalendarItemRetrieveApiArg = {
  /** A unique integer value identifying this calendar item. */
  id: number;
};
export type PlannerCalendarItemUpdateApiResponse = /** status 200  */ CalendarItem;
export type PlannerCalendarItemUpdateApiArg = {
  /** A unique integer value identifying this calendar item. */
  id: number;
  calendarItemRequest: CalendarItemRequest;
};
export type PlannerCalendarItemPartialUpdateApiResponse = /** status 200  */ CalendarItem;
export type PlannerCalendarItemPartialUpdateApiArg = {
  /** A unique integer value identifying this calendar item. */
  id: number;
  patchedCalendarItemRequest: PatchedCalendarItemRequest;
};
export type PlannerCalendarItemDestroyApiResponse = unknown;
export type PlannerCalendarItemDestroyApiArg = {
  /** A unique integer value identifying this calendar item. */
  id: number;
};
export type PlannerCopyMealsFromDateCreateApiResponse = /** status 201  */ CopyFromDate;
export type PlannerCopyMealsFromDateCreateApiArg = {
  copyFromDateRequest: CopyFromDateRequest;
};
export type PlannerCopyMealsToMealMomentCreateApiResponse = /** status 201  */ CopyMealsToMealMoment;
export type PlannerCopyMealsToMealMomentCreateApiArg = {
  copyMealsToMealMomentRequest: CopyMealsToMealMomentRequest;
};
export type PlannerEditRecipeMealPortionsUpdateApiResponse = /** status 200  */ RecipeMealPotions;
export type PlannerEditRecipeMealPortionsUpdateApiArg = {
  id: string;
  recipeMealPotionsRequest: RecipeMealPotionsRequest;
};
export type PlannerGroceryListListApiResponse = /** status 200  */ PaginatedGroceryListListRetrieveList;
export type PlannerGroceryListListApiArg = {
  /** A page number within the paginated result set. */
  page?: number;
  user?: number;
};
export type PlannerGroceryListCreateApiResponse = /** status 201  */ GroceryListListRetrieve;
export type PlannerGroceryListCreateApiArg = {
  groceryListUpdateCreateRequest: GroceryListUpdateCreateRequest;
};
export type PlannerGroceryListRetrieveApiResponse = /** status 200  */ GroceryListListRetrieve;
export type PlannerGroceryListRetrieveApiArg = {
  /** A unique integer value identifying this grocery list. */
  id: number;
};
export type PlannerGroceryListUpdateApiResponse = /** status 200  */ GroceryListListRetrieve;
export type PlannerGroceryListUpdateApiArg = {
  /** A unique integer value identifying this grocery list. */
  id: number;
};
export type PlannerGroceryListPartialUpdateApiResponse = /** status 200  */ GroceryListListRetrieve;
export type PlannerGroceryListPartialUpdateApiArg = {
  /** A unique integer value identifying this grocery list. */
  id: number;
};
export type PlannerGroceryListDestroyApiResponse = unknown;
export type PlannerGroceryListDestroyApiArg = {
  /** A unique integer value identifying this grocery list. */
  id: number;
};
export type PlannerGroceryListItemPartialUpdateApiResponse = /** status 200  */ GroceryListItemUpdate;
export type PlannerGroceryListItemPartialUpdateApiArg = {
  /** A unique integer value identifying this grocery list item. */
  id: number;
  patchedGroceryListItemUpdateRequest: PatchedGroceryListItemUpdateRequest;
};
export type PlannerPlanGeneratedRecipeMealCreateApiResponse = /** status 201  */ PlanGeneratedRecipeMeal;
export type PlannerPlanGeneratedRecipeMealCreateApiArg = {
  planGeneratedRecipeMealRequest: PlanGeneratedRecipeMealRequest;
};
export type PlannerPlanSingleFoodMealCreateApiResponse = /** status 201  */ PlanSingleFoodMeal;
export type PlannerPlanSingleFoodMealCreateApiArg = {
  planSingleFoodMealRequest: PlanSingleFoodMealRequest;
};
export type PlannerSaveAndPlanRecipeMealCreateApiResponse = /** status 201  */ SaveAndPlanRecipeTemplate;
export type PlannerSaveAndPlanRecipeMealCreateApiArg = {
  saveAndPlanRecipeTemplateRequest: SaveAndPlanRecipeTemplateRequest;
};
export type SchemaRetrieveApiResponse = /** status 200  */ {
  [key: string]: any;
};
export type SchemaRetrieveApiArg = {
  format?: "json" | "yaml";
  lang?: "en-us" | "nl";
};
export type UsersAuthTokenLoginCreateApiResponse = /** status 200  */ TokenCreate;
export type UsersAuthTokenLoginCreateApiArg = {
  tokenCreateRequest: TokenCreateRequest;
};
export type UsersAuthTokenLogoutCreateApiResponse = unknown;
export type UsersAuthTokenLogoutCreateApiArg = void;
export type UsersAuthUsersListApiResponse = /** status 200  */ PaginatedUserList;
export type UsersAuthUsersListApiArg = {
  /** A page number within the paginated result set. */
  page?: number;
};
export type UsersAuthUsersCreateApiResponse = /** status 201  */ CustomUserCreate;
export type UsersAuthUsersCreateApiArg = {
  customUserCreateRequest: CustomUserCreateRequest;
};
export type UsersAuthUsersRetrieveApiResponse = /** status 200  */ User;
export type UsersAuthUsersRetrieveApiArg = {
  /** A unique integer value identifying this user. */
  id: number;
};
export type UsersAuthUsersUpdateApiResponse = /** status 200  */ User;
export type UsersAuthUsersUpdateApiArg = {
  /** A unique integer value identifying this user. */
  id: number;
  userRequest: UserRequest;
};
export type UsersAuthUsersPartialUpdateApiResponse = /** status 200  */ User;
export type UsersAuthUsersPartialUpdateApiArg = {
  /** A unique integer value identifying this user. */
  id: number;
  patchedUserRequest: PatchedUserRequest;
};
export type UsersAuthUsersDestroyApiResponse = unknown;
export type UsersAuthUsersDestroyApiArg = {
  /** A unique integer value identifying this user. */
  id: number;
};
export type UsersAuthUsersActivationCreateApiResponse = /** status 200  */ Activation;
export type UsersAuthUsersActivationCreateApiArg = {
  activationRequest: ActivationRequest;
};
export type UsersAuthUsersMeRetrieveApiResponse = /** status 200  */ User;
export type UsersAuthUsersMeRetrieveApiArg = void;
export type UsersAuthUsersMeUpdateApiResponse = /** status 200  */ User;
export type UsersAuthUsersMeUpdateApiArg = {
  userRequest: UserRequest;
};
export type UsersAuthUsersMePartialUpdateApiResponse = /** status 200  */ User;
export type UsersAuthUsersMePartialUpdateApiArg = {
  patchedUserRequest: PatchedUserRequest;
};
export type UsersAuthUsersMeDestroyApiResponse = unknown;
export type UsersAuthUsersMeDestroyApiArg = void;
export type UsersAuthUsersResendActivationCreateApiResponse = /** status 200  */ SendEmailReset;
export type UsersAuthUsersResendActivationCreateApiArg = {
  sendEmailResetRequest: SendEmailResetRequest;
};
export type UsersAuthUsersResetEmailCreateApiResponse = /** status 200  */ SendEmailReset;
export type UsersAuthUsersResetEmailCreateApiArg = {
  sendEmailResetRequest: SendEmailResetRequest;
};
export type UsersAuthUsersResetEmailConfirmCreateApiResponse = /** status 200  */ UsernameResetConfirm;
export type UsersAuthUsersResetEmailConfirmCreateApiArg = {
  usernameResetConfirmRequest: UsernameResetConfirmRequest;
};
export type UsersAuthUsersResetPasswordCreateApiResponse = /** status 200  */ SendEmailReset;
export type UsersAuthUsersResetPasswordCreateApiArg = {
  sendEmailResetRequest: SendEmailResetRequest;
};
export type UsersAuthUsersResetPasswordConfirmCreateApiResponse = /** status 200  */ PasswordResetConfirm;
export type UsersAuthUsersResetPasswordConfirmCreateApiArg = {
  passwordResetConfirmRequest: PasswordResetConfirmRequest;
};
export type UsersAuthUsersSetEmailCreateApiResponse = /** status 200  */ SetUsername;
export type UsersAuthUsersSetEmailCreateApiArg = {
  setUsernameRequest: SetUsernameRequest;
};
export type UsersAuthUsersSetPasswordCreateApiResponse = /** status 200  */ SetPassword;
export type UsersAuthUsersSetPasswordCreateApiArg = {
  setPasswordRequest: SetPasswordRequest;
};
export type UsersBrandListApiResponse = /** status 200  */ PaginatedBrandList;
export type UsersBrandListApiArg = {
  /** A page number within the paginated result set. */
  page?: number;
};
export type UsersBrandRetrieveApiResponse = /** status 200  */ Brand;
export type UsersBrandRetrieveApiArg = {
  subdomain: string;
};
export type UsersCancelDealCreateApiResponse = /** status 200  */ CancelSubscription;
export type UsersCancelDealCreateApiArg = {
  cancelSubscriptionRequest: CancelSubscriptionRequest;
};
export type UsersClientsListApiResponse = /** status 200  */ User[];
export type UsersClientsListApiArg = void;
export type UsersClientsCreateApiResponse = /** status 201  */ User;
export type UsersClientsCreateApiArg = {
  userRequest: UserRequest;
};
export type UsersClientsRetrieveApiResponse = /** status 200  */ User;
export type UsersClientsRetrieveApiArg = {
  /** A unique integer value identifying this user. */
  id: number;
};
export type UsersClientsUpdateApiResponse = /** status 200  */ User;
export type UsersClientsUpdateApiArg = {
  /** A unique integer value identifying this user. */
  id: number;
  userRequest: UserRequest;
};
export type UsersClientsPartialUpdateApiResponse = /** status 200  */ User;
export type UsersClientsPartialUpdateApiArg = {
  /** A unique integer value identifying this user. */
  id: number;
  patchedUserRequest: PatchedUserRequest;
};
export type UsersClientsDestroyApiResponse = unknown;
export type UsersClientsDestroyApiArg = {
  /** A unique integer value identifying this user. */
  id: number;
};
export type UsersCreateLoginSignupPortalCreateApiResponse = /** status 200  */ LoginSignupPortalResponse;
export type UsersCreateLoginSignupPortalCreateApiArg = {
  loginSignupPortalRequest: LoginSignupPortalRequest;
};
export type UsersCreateMagicLinkCreateApiResponse = /** status 200  */ MagicLinkCreate;
export type UsersCreateMagicLinkCreateApiArg = {
  magicLinkCreateRequest: MagicLinkCreateRequest;
};
export type UsersCreateOtpCreateApiResponse = /** status 200  */ SendOtp;
export type UsersCreateOtpCreateApiArg = {
  sendOtpRequest: SendOtpRequest;
};
export type UsersCustomerPortalRedirectRetrieveApiResponse = unknown;
export type UsersCustomerPortalRedirectRetrieveApiArg = {
  email: string;
  id: string;
};
export type UsersExerciseInstanceListApiResponse = /** status 200  */ PaginatedExerciseInstanceListRetrieveList;
export type UsersExerciseInstanceListApiArg = {
  /** A page number within the paginated result set. */
  page?: number;
};
export type UsersExerciseInstanceCreateApiResponse = /** status 201  */ ExerciseInstanceCreateUpdate;
export type UsersExerciseInstanceCreateApiArg = {
  exerciseInstanceCreateUpdateRequest: ExerciseInstanceCreateUpdateRequest;
};
export type UsersExerciseInstanceRetrieveApiResponse = /** status 200  */ ExerciseInstanceCreateUpdate;
export type UsersExerciseInstanceRetrieveApiArg = {
  /** A unique integer value identifying this exercise instance. */
  id: number;
};
export type UsersExerciseInstanceUpdateApiResponse = /** status 200  */ ExerciseInstanceCreateUpdate;
export type UsersExerciseInstanceUpdateApiArg = {
  /** A unique integer value identifying this exercise instance. */
  id: number;
  exerciseInstanceCreateUpdateRequest: ExerciseInstanceCreateUpdateRequest;
};
export type UsersExerciseInstancePartialUpdateApiResponse = /** status 200  */ ExerciseInstanceCreateUpdate;
export type UsersExerciseInstancePartialUpdateApiArg = {
  /** A unique integer value identifying this exercise instance. */
  id: number;
  patchedExerciseInstanceCreateUpdateRequest: PatchedExerciseInstanceCreateUpdateRequest;
};
export type UsersExerciseInstanceDestroyApiResponse = unknown;
export type UsersExerciseInstanceDestroyApiArg = {
  /** A unique integer value identifying this exercise instance. */
  id: number;
};
export type UsersExerciseTemplateListApiResponse = /** status 200  */ PaginatedExerciseTemplateList;
export type UsersExerciseTemplateListApiArg = {
  /** A page number within the paginated result set. */
  page?: number;
};
export type UsersExerciseTemplateRetrieveApiResponse = /** status 200  */ ExerciseTemplate;
export type UsersExerciseTemplateRetrieveApiArg = {
  /** A unique integer value identifying this exercise template. */
  id: number;
};
export type UsersMealSlotSpecificationListApiResponse = /** status 200  */ PaginatedMealSlotSpecificationList;
export type UsersMealSlotSpecificationListApiArg = {
  /** A page number within the paginated result set. */
  page?: number;
};
export type UsersMealSlotSpecificationCreateApiResponse = /** status 201  */ MealSlotSpecification;
export type UsersMealSlotSpecificationCreateApiArg = {
  mealSlotSpecificationRequest: MealSlotSpecificationRequest;
};
export type UsersMealSlotSpecificationRetrieveApiResponse = /** status 200  */ MealSlotSpecification;
export type UsersMealSlotSpecificationRetrieveApiArg = {
  /** A unique integer value identifying this meal slot specification. */
  id: number;
};
export type UsersMealSlotSpecificationUpdateApiResponse = /** status 200  */ MealSlotSpecification;
export type UsersMealSlotSpecificationUpdateApiArg = {
  /** A unique integer value identifying this meal slot specification. */
  id: number;
  mealSlotSpecificationRequest: MealSlotSpecificationRequest;
};
export type UsersMealSlotSpecificationPartialUpdateApiResponse = /** status 200  */ MealSlotSpecification;
export type UsersMealSlotSpecificationPartialUpdateApiArg = {
  /** A unique integer value identifying this meal slot specification. */
  id: number;
  patchedMealSlotSpecificationRequest: PatchedMealSlotSpecificationRequest;
};
export type UsersMealSlotSpecificationDestroyApiResponse = unknown;
export type UsersMealSlotSpecificationDestroyApiArg = {
  /** A unique integer value identifying this meal slot specification. */
  id: number;
};
export type UsersNutritionDayPlanListApiResponse = /** status 200  */ PaginatedNutritionDayPlanList;
export type UsersNutritionDayPlanListApiArg = {
  /** A page number within the paginated result set. */
  page?: number;
};
export type UsersNutritionDayPlanCreateApiResponse = /** status 201  */ NutritionDayPlan;
export type UsersNutritionDayPlanCreateApiArg = {
  nutritionDayPlanRequest: NutritionDayPlanRequest;
};
export type UsersNutritionDayPlanRetrieveApiResponse = /** status 200  */ NutritionDayPlan;
export type UsersNutritionDayPlanRetrieveApiArg = {
  /** A unique integer value identifying this nutrition day plan. */
  id: number;
};
export type UsersNutritionDayPlanUpdateApiResponse = /** status 200  */ NutritionDayPlan;
export type UsersNutritionDayPlanUpdateApiArg = {
  /** A unique integer value identifying this nutrition day plan. */
  id: number;
  nutritionDayPlanRequest: NutritionDayPlanRequest;
};
export type UsersNutritionDayPlanPartialUpdateApiResponse = /** status 200  */ NutritionDayPlan;
export type UsersNutritionDayPlanPartialUpdateApiArg = {
  /** A unique integer value identifying this nutrition day plan. */
  id: number;
  patchedNutritionDayPlanRequest: PatchedNutritionDayPlanRequest;
};
export type UsersNutritionDayPlanDestroyApiResponse = unknown;
export type UsersNutritionDayPlanDestroyApiArg = {
  /** A unique integer value identifying this nutrition day plan. */
  id: number;
};
export type UsersOrganisationCreateApiResponse = /** status 201  */ UpdateCreateOrganisation;
export type UsersOrganisationCreateApiArg = {
  updateCreateOrganisationRequest: UpdateCreateOrganisationRequest;
};
export type UsersOrganisationPartialUpdateApiResponse = /** status 200  */ UpdateCreateOrganisation;
export type UsersOrganisationPartialUpdateApiArg = {
  /** A unique integer value identifying this organisation. */
  id: number;
  patchedUpdateCreateOrganisationRequest: PatchedUpdateCreateOrganisationRequest;
};
export type UsersPaginatedclientsListApiResponse = /** status 200  */ PaginatedSlimClientList;
export type UsersPaginatedclientsListApiArg = {
  accountEnabled?: boolean;
  /** A page number within the paginated result set. */
  page?: number;
  /** A search term. */
  search?: string;
};
export type UsersPaginatedclientsCreateApiResponse = /** status 201  */ SlimClient;
export type UsersPaginatedclientsCreateApiArg = {
  slimClientRequest: SlimClientRequest;
};
export type UsersPaginatedclientsRetrieveApiResponse = /** status 200  */ SlimClient;
export type UsersPaginatedclientsRetrieveApiArg = {
  /** A unique integer value identifying this user. */
  id: number;
};
export type UsersPaginatedclientsUpdateApiResponse = /** status 200  */ SlimClient;
export type UsersPaginatedclientsUpdateApiArg = {
  /** A unique integer value identifying this user. */
  id: number;
  slimClientRequest: SlimClientRequest;
};
export type UsersPaginatedclientsPartialUpdateApiResponse = /** status 200  */ SlimClient;
export type UsersPaginatedclientsPartialUpdateApiArg = {
  /** A unique integer value identifying this user. */
  id: number;
  patchedSlimClientRequest: PatchedSlimClientRequest;
};
export type UsersPaginatedclientsDestroyApiResponse = unknown;
export type UsersPaginatedclientsDestroyApiArg = {
  /** A unique integer value identifying this user. */
  id: number;
};
export type UsersSetIngredientsToAvoidCreateApiResponse = /** status 200  */ SetIngredientToAvoid;
export type UsersSetIngredientsToAvoidCreateApiArg = {
  setIngredientToAvoidRequest: SetIngredientToAvoidRequest;
};
export type UsersStripeCustomerPortalCreateApiResponse = /** status 201  */ StripeCustomerPortal;
export type UsersStripeCustomerPortalCreateApiArg = void;
export type UsersUserListApiResponse = /** status 200  */ PaginatedUserList;
export type UsersUserListApiArg = {
  /** A page number within the paginated result set. */
  page?: number;
};
export type UsersUserCreateApiResponse = /** status 201  */ User;
export type UsersUserCreateApiArg = {
  userRequest: UserRequest;
};
export type UsersUserRetrieveApiResponse = /** status 200  */ User;
export type UsersUserRetrieveApiArg = {
  /** A unique integer value identifying this user. */
  id: number;
};
export type UsersUserUpdateApiResponse = /** status 200  */ User;
export type UsersUserUpdateApiArg = {
  /** A unique integer value identifying this user. */
  id: number;
  userRequest: UserRequest;
};
export type UsersUserPartialUpdateApiResponse = /** status 200  */ User;
export type UsersUserPartialUpdateApiArg = {
  /** A unique integer value identifying this user. */
  id: number;
  patchedUserRequest: PatchedUserRequest;
};
export type UsersUserDestroyApiResponse = unknown;
export type UsersUserDestroyApiArg = {
  /** A unique integer value identifying this user. */
  id: number;
};
export type UsersUserProfileListApiResponse = /** status 200  */ PaginatedUserProfileList;
export type UsersUserProfileListApiArg = {
  /** A page number within the paginated result set. */
  page?: number;
};
export type UsersUserProfileCreateApiResponse = /** status 201  */ UserProfile;
export type UsersUserProfileCreateApiArg = {
  userProfileRequest: UserProfileRequest;
};
export type UsersUserProfileRetrieveApiResponse = /** status 200  */ UserProfile;
export type UsersUserProfileRetrieveApiArg = {
  /** A unique integer value identifying this user profile. */
  id: number;
};
export type UsersUserProfileUpdateApiResponse = /** status 200  */ UserProfile;
export type UsersUserProfileUpdateApiArg = {
  /** A unique integer value identifying this user profile. */
  id: number;
  userProfileRequest: UserProfileRequest;
};
export type UsersUserProfilePartialUpdateApiResponse = /** status 200  */ UserProfile;
export type UsersUserProfilePartialUpdateApiArg = {
  /** A unique integer value identifying this user profile. */
  id: number;
  patchedUserProfileRequest: PatchedUserProfileRequest;
};
export type UsersUserProfileDestroyApiResponse = unknown;
export type UsersUserProfileDestroyApiArg = {
  /** A unique integer value identifying this user profile. */
  id: number;
};
export type UsersUserdislikerecipeListApiResponse = /** status 200  */ PaginatedUserDislikeRecipeList;
export type UsersUserdislikerecipeListApiArg = {
  /** A page number within the paginated result set. */
  page?: number;
  recipe?: number;
  user?: number;
};
export type UsersUserdislikerecipeCreateApiResponse = /** status 201  */ UserDislikeRecipe;
export type UsersUserdislikerecipeCreateApiArg = {
  userDislikeRecipeRequest: UserDislikeRecipeRequest;
};
export type UsersUserdislikerecipeRetrieveApiResponse = /** status 200  */ UserDislikeRecipe;
export type UsersUserdislikerecipeRetrieveApiArg = {
  /** A unique integer value identifying this user dislike recipe. */
  id: number;
};
export type UsersUserdislikerecipeUpdateApiResponse = /** status 200  */ UserDislikeRecipe;
export type UsersUserdislikerecipeUpdateApiArg = {
  /** A unique integer value identifying this user dislike recipe. */
  id: number;
  userDislikeRecipeRequest: UserDislikeRecipeRequest;
};
export type UsersUserdislikerecipePartialUpdateApiResponse = /** status 200  */ UserDislikeRecipe;
export type UsersUserdislikerecipePartialUpdateApiArg = {
  /** A unique integer value identifying this user dislike recipe. */
  id: number;
  patchedUserDislikeRecipeRequest: PatchedUserDislikeRecipeRequest;
};
export type UsersUserdislikerecipeDestroyApiResponse = unknown;
export type UsersUserdislikerecipeDestroyApiArg = {
  /** A unique integer value identifying this user dislike recipe. */
  id: number;
};
export type UsersUserfavouriterecipeListApiResponse = /** status 200  */ PaginatedUserFavouriteRecipeList;
export type UsersUserfavouriterecipeListApiArg = {
  /** A page number within the paginated result set. */
  page?: number;
  recipe?: number;
  user?: number;
};
export type UsersUserfavouriterecipeCreateApiResponse = /** status 201  */ UserFavouriteRecipe;
export type UsersUserfavouriterecipeCreateApiArg = {
  userFavouriteRecipeRequest: UserFavouriteRecipeRequest;
};
export type UsersUserfavouriterecipeRetrieveApiResponse = /** status 200  */ UserFavouriteRecipe;
export type UsersUserfavouriterecipeRetrieveApiArg = {
  /** A unique integer value identifying this user favourite recipe. */
  id: number;
};
export type UsersUserfavouriterecipeUpdateApiResponse = /** status 200  */ UserFavouriteRecipe;
export type UsersUserfavouriterecipeUpdateApiArg = {
  /** A unique integer value identifying this user favourite recipe. */
  id: number;
  userFavouriteRecipeRequest: UserFavouriteRecipeRequest;
};
export type UsersUserfavouriterecipePartialUpdateApiResponse = /** status 200  */ UserFavouriteRecipe;
export type UsersUserfavouriterecipePartialUpdateApiArg = {
  /** A unique integer value identifying this user favourite recipe. */
  id: number;
  patchedUserFavouriteRecipeRequest: PatchedUserFavouriteRecipeRequest;
};
export type UsersUserfavouriterecipeDestroyApiResponse = unknown;
export type UsersUserfavouriterecipeDestroyApiArg = {
  /** A unique integer value identifying this user favourite recipe. */
  id: number;
};
export type UsersVerifyOtpCreateApiResponse = /** status 200  */ VerifyOtpResponse;
export type UsersVerifyOtpCreateApiArg = {
  verifyOtpRequest: VerifyOtpRequest;
};
export type UsersWeeklyNutritionPlanListApiResponse = /** status 200  */ PaginatedWeeklyNutritionPlanList;
export type UsersWeeklyNutritionPlanListApiArg = {
  /** A page number within the paginated result set. */
  page?: number;
};
export type UsersWeeklyNutritionPlanCreateApiResponse = /** status 201  */ WeeklyNutritionPlanCreate;
export type UsersWeeklyNutritionPlanCreateApiArg = {
  weeklyNutritionPlanCreateRequest: WeeklyNutritionPlanCreateRequest;
};
export type UsersWeeklyNutritionPlanRetrieveApiResponse = /** status 200  */ WeeklyNutritionPlan;
export type UsersWeeklyNutritionPlanRetrieveApiArg = {
  /** A unique integer value identifying this weekly nutrition plan. */
  id: number;
};
export type UsersWeeklyNutritionPlanUpdateApiResponse = /** status 200  */ WeeklyNutritionPlanUpdate;
export type UsersWeeklyNutritionPlanUpdateApiArg = {
  /** A unique integer value identifying this weekly nutrition plan. */
  id: number;
  weeklyNutritionPlanUpdateRequest: WeeklyNutritionPlanUpdateRequest;
};
export type V2SchemaRetrieveApiResponse = /** status 200  */ {
  [key: string]: any;
};
export type V2SchemaRetrieveApiArg = {
  format?: "json" | "yaml";
  lang?: "en-us" | "nl";
};
export type V3SchemaRetrieveApiResponse = /** status 200  */ {
  [key: string]: any;
};
export type V3SchemaRetrieveApiArg = {
  format?: "json" | "yaml";
  lang?: "en-us" | "nl";
};
export type SubscriptionVerificationRequest = {
  email: string;
};
export type RecipeTagNameEnum =
  | "ROOT_NODE"
  | "BREAD"
  | "BREAKFAST"
  | "BURGER"
  | "COUSCOUS_AND_QUINOA"
  | "DAIRY"
  | "DINNER"
  | "DIPS_AND_SPREADS"
  | "FAVOURITE"
  | "KETO"
  | "LOW_VARIANTS"
  | "LUNCH"
  | "PANCAKES_AND_OMELETTES"
  | "PASTA"
  | "POTATO"
  | "PRE_BED"
  | "RICE_AND_NOODLES"
  | "SALAD"
  | "SNACK"
  | "SNACKS_AND_BAKES"
  | "SOUP"
  | "VEGAN"
  | "WRAP"
  | "CRUSTACEANS_AND_SHELLFISH"
  | "HALAL"
  | "OTHER";
export type RecipeTag = {
  id: number;
  name: RecipeTagNameEnum;
};
export type SourceProviderEnum = "WEEKMEALS" | "FATSECRET" | "USER_GENERATED" | "BRANDED";
export type ContentTypeB41Enum = "singlefoodmeal" | "recipetemplate" | "recipemeal";
export type SuggestedServing = {
  id?: number;
  kcal: number;
  protein: number;
  carbohydrates: number;
  fat: number;
  fiber?: number;
  calcium?: number;
  cholesterol?: number;
  iron?: number;
  monounsaturated_fat?: number;
  polyunsaturated_fat?: number;
  potassium?: number;
  saturated_fat?: number;
  sodium?: number;
  sugar?: number;
  vitamin_a?: number;
  vitamin_c?: number;
  food: number;
  order?: number;
  one_serving_in_metric_value?: number | null;
  quantity: number;
  measurement_description: string;
  serving_description: string;
};
export type Ingredient = {
  id?: number;
  name: string;
  content_type: ContentTypeB41Enum;
  suggested_serving?: SuggestedServing;
  created: string;
  modified: string;
  optional?: boolean;
  quantity: number;
  legacy_min_quantity?: number | null;
  legacy_max_quantity?: number | null;
  object_id: number;
};
export type RecipeTemplateDirection = {
  id: number;
  recipe_template: number;
  optional?: boolean;
  order?: number;
  text: string;
};
export type RecipeTemplate = {
  id?: number;
  name: string;
  cooking_time_min?: number | null;
  preparation_time_min?: number | null;
  tags: RecipeTag[];
  published?: boolean;
  source_provider: SourceProviderEnum;
  author?: number | null;
  ingredients: Ingredient[];
  directions: RecipeTemplateDirection[];
  image?: string;
  external_url?: string | null;
  kcal: number;
  protein: number;
  carbohydrates: number;
  fat: number;
  fiber: number;
};
export type RecipeTagRequest = {
  name: RecipeTagNameEnum;
};
export type RecipeTemplateRequest = {
  id?: number;
  name: string;
  cooking_time_min?: number | null;
  preparation_time_min?: number | null;
  tags: RecipeTagRequest[];
  published?: boolean;
  source_provider: SourceProviderEnum;
  author?: number | null;
  image?: Blob;
  external_url?: string | null;
};
export type SuggestedServingRequest = {
  id?: number;
  kcal: number;
  protein: number;
  carbohydrates: number;
  fat: number;
  fiber?: number;
  calcium?: number;
  cholesterol?: number;
  iron?: number;
  monounsaturated_fat?: number;
  polyunsaturated_fat?: number;
  potassium?: number;
  saturated_fat?: number;
  sodium?: number;
  sugar?: number;
  vitamin_a?: number;
  vitamin_c?: number;
  food: number;
  order?: number;
  one_serving_in_metric_value?: number | null;
  quantity: number;
};
export type GeneratedRecipeMealIngredientRequest = {
  suggested_serving: SuggestedServingRequest;
  name: string;
  quantity: number;
  content_type: number;
  optional?: boolean;
};
export type GeneratedRecipeMealDirectionRequest = {
  text: string;
};
export type CreateNewRecipeTemplateRequest = {
  recipe_template: RecipeTemplateRequest;
  ingredients: GeneratedRecipeMealIngredientRequest[];
  directions: GeneratedRecipeMealDirectionRequest[];
  author: number;
  portions: number;
  kcal: number;
  protein: number;
  fat: number;
  carbohydrates: number;
  fiber: number;
};
export type LegacyMajorMacroEnum = "PROTEIN" | "FAT" | "CARBOHYDRATE" | "SATIETY";
export type BlankEnum = "";
export type NullEnum = null;
export type GroceryCategoryEnum =
  | "ASIAN"
  | "BAKING"
  | "BREAD"
  | "CHIPS"
  | "CHOCOLATE"
  | "COFFEE"
  | "CRACKER"
  | "DAIRY"
  | "DELI"
  | "DRINK"
  | "FREEZER"
  | "FRUIT"
  | "HERB"
  | "MEAT"
  | "MEXICAN"
  | "NUT"
  | "OAT"
  | "OIL"
  | "ONLINE"
  | "PASTA"
  | "POWERSLIM"
  | "PRESERVED"
  | "READY"
  | "REFRIGERATED"
  | "RICE"
  | "SAUCE"
  | "SEED"
  | "SOUP"
  | "SPICE"
  | "SPREAD"
  | "VEGETABLE"
  | "WHEAT"
  | "WINE"
  | "WRAP"
  | "OTHER";
export type Name756Enum =
  | "ANIMAL"
  | "BEEF"
  | "CHICKEN"
  | "COCONUT"
  | "CORN"
  | "CURRY"
  | "DAIRY"
  | "EGG"
  | "FISH"
  | "FRUIT"
  | "HARD_CHEESE"
  | "HERB"
  | "LAMB"
  | "LEGUME"
  | "LEMON"
  | "NUT"
  | "OAT"
  | "PEA"
  | "PORK"
  | "POULTRY"
  | "POWERSLIM"
  | "RICE"
  | "SEAFOOD"
  | "SEED"
  | "SOFT_CHEESE"
  | "SOYBEAN"
  | "SPICE"
  | "TOMATO"
  | "TUBER"
  | "TURKEY"
  | "VEGETABLE"
  | "WHEAT"
  | "OTHER";
export type Nested = {
  id: number;
  slug: string;
  active?: boolean;
  name: Name756Enum;
  lft: number;
  rght: number;
  tree_id: number;
  level: number;
  parent: Nested;
};
export type FoodCategory = {
  id: number;
  slug: string;
  active?: boolean;
  name: Name756Enum;
  lft: number;
  rght: number;
  tree_id: number;
  level: number;
  parent: Nested;
};
export type Food = {
  id?: number;
  brand_name?: string | null;
  legacy_reference_name?: string;
  legacy_major_macro?: (LegacyMajorMacroEnum | BlankEnum | NullEnum) | null;
  legacy_is_healthy?: number;
  max?: number;
  grocery_category?: (GroceryCategoryEnum | BlankEnum | NullEnum) | null;
  legacy_source_url?: string | null;
  source_provider: SourceProviderEnum;
  source_id: string;
  name: string;
  published?: boolean;
  suggested_servings: SuggestedServing[];
  categories: FoodCategory[];
};
export type PaginatedFoodList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: Food[];
};
export type GeneratedRecipeMealIngredient = {
  suggested_serving: SuggestedServing;
  name: string;
  quantity: number;
  content_type: number;
  optional?: boolean;
};
export type GeneratedRecipeMealDirection = {
  text: string;
};
export type GeneratedRecipeMeal = {
  recipe_template: RecipeTemplate;
  ingredients: GeneratedRecipeMealIngredient[];
  directions: GeneratedRecipeMealDirection[];
  portions: number;
  kcal: number;
  protein: number;
  fat: number;
  carbohydrates: number;
  fiber: number;
};
export type PaginatedGeneratedRecipeMealList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: GeneratedRecipeMeal[];
};
export type RecipeTemplateSlim = {
  id?: number;
  name: string;
  cooking_time_min?: number | null;
  preparation_time_min?: number | null;
  published?: boolean;
  source_provider: SourceProviderEnum;
  author?: number | null;
  image?: string;
  external_url?: string | null;
};
export type RecipeMealRecipeDirection = {
  id: number;
  optional?: boolean;
  order?: number;
  text: string;
  recipe_meal: number;
};
export type RecipeMeal = {
  id: number;
  recipe_template: RecipeTemplateSlim;
  portions: number;
  kcal: number;
  protein: number;
  carbohydrates: number;
  fat: number;
  fiber: number;
  ingredients: Ingredient[];
  directions: RecipeMealRecipeDirection[];
};
export type GeneratedRecipeMealRequest = {
  recipe_template: RecipeTemplateRequest;
  ingredients: GeneratedRecipeMealIngredientRequest[];
  directions: GeneratedRecipeMealDirectionRequest[];
  portions: number;
  kcal: number;
  protein: number;
  fat: number;
  carbohydrates: number;
  fiber: number;
};
export type PaginatedIngredientList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: Ingredient[];
};
export type IngredientPost = {
  id?: number;
  name: string;
  content_type: ContentTypeB41Enum;
  created: string;
  modified: string;
  optional?: boolean;
  quantity: number;
  legacy_min_quantity?: number | null;
  legacy_max_quantity?: number | null;
  object_id: number;
  suggested_serving: number;
};
export type IngredientPostRequest = {
  id?: number;
  content_type: ContentTypeB41Enum;
  optional?: boolean;
  quantity: number;
  legacy_min_quantity?: number | null;
  legacy_max_quantity?: number | null;
  object_id: number;
  suggested_serving: number;
};
export type IngredientRequest = {
  id?: number;
  content_type: ContentTypeB41Enum;
  suggested_serving?: SuggestedServingRequest;
  optional?: boolean;
  quantity: number;
  legacy_min_quantity?: number | null;
  legacy_max_quantity?: number | null;
  object_id: number;
};
export type PatchedIngredientRequest = {
  id?: number;
  content_type?: ContentTypeB41Enum;
  suggested_serving?: SuggestedServingRequest;
  optional?: boolean;
  quantity?: number;
  legacy_min_quantity?: number | null;
  legacy_max_quantity?: number | null;
  object_id?: number;
};
export type IngredientToAvoid = {
  id: string;
  name: string;
  translations: number[];
  keywords: any;
  emoji: string;
};
export type PaginatedIngredientToAvoidList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: IngredientToAvoid[];
};
export type SuggestedServingSearch = {
  id?: number;
  kcal: number;
  protein: number;
  carbohydrates: number;
  fat: number;
  food_name: string;
  one_serving_in_metric_value?: number | null;
  serving_description: string;
  legacy_reference_name: string | null;
};
export type PreferredSuggestedServing = {
  reference_name_in_recipe: string;
  organisation: number;
  suggested_serving: SuggestedServingSearch;
};
export type PaginatedPreferredSuggestedServingList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: PreferredSuggestedServing[];
};
export type SuggestedServingSearchRequest = {
  id?: number;
  kcal: number;
  protein: number;
  carbohydrates: number;
  fat: number;
  one_serving_in_metric_value?: number | null;
};
export type PreferredSuggestedServingRequest = {
  reference_name_in_recipe: string;
  organisation: number;
  suggested_serving: SuggestedServingSearchRequest;
};
export type PatchedPreferredSuggestedServingRequest = {
  reference_name_in_recipe?: string;
  organisation?: number;
  suggested_serving?: SuggestedServingSearchRequest;
};
export type QuickAddMeal = {
  id: number;
  kcal: number;
  protein?: number;
  carbohydrates?: number;
  fat?: number;
  fiber?: number;
  calcium?: number;
  cholesterol?: number;
  iron?: number;
  monounsaturated_fat?: number;
  polyunsaturated_fat?: number;
  potassium?: number;
  saturated_fat?: number;
  sodium?: number;
  sugar?: number;
  vitamin_a?: number;
  vitamin_c?: number;
};
export type PaginatedQuickAddMealList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: QuickAddMeal[];
};
export type QuickAddMealRequest = {
  kcal: number;
  protein?: number;
  carbohydrates?: number;
  fat?: number;
  fiber?: number;
  calcium?: number;
  cholesterol?: number;
  iron?: number;
  monounsaturated_fat?: number;
  polyunsaturated_fat?: number;
  potassium?: number;
  saturated_fat?: number;
  sodium?: number;
  sugar?: number;
  vitamin_a?: number;
  vitamin_c?: number;
};
export type PatchedQuickAddMealRequest = {
  kcal?: number;
  protein?: number;
  carbohydrates?: number;
  fat?: number;
  fiber?: number;
  calcium?: number;
  cholesterol?: number;
  iron?: number;
  monounsaturated_fat?: number;
  polyunsaturated_fat?: number;
  potassium?: number;
  saturated_fat?: number;
  sodium?: number;
  sugar?: number;
  vitamin_a?: number;
  vitamin_c?: number;
};
export type RecipeFeedbackLineItem = {
  id: string;
  content_type: string;
  object_id: number;
  feedback_text: string;
  resolved?: boolean;
  author_name?: string;
  timestamp: string;
};
export type PaginatedRecipeFeedbackLineItemList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: RecipeFeedbackLineItem[];
};
export type RecipeFeedbackLineItemRequest = {
  content_type: string;
  object_id: number;
  feedback_text: string;
  resolved?: boolean;
  author_name?: string;
};
export type PatchedRecipeFeedbackLineItemRequest = {
  content_type?: string;
  object_id?: number;
  feedback_text?: string;
  resolved?: boolean;
  author_name?: string;
};
export type PaginatedRecipeMealList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: RecipeMeal[];
};
export type RecipeTemplateSlimRequest = {
  id?: number;
  cooking_time_min?: number | null;
  preparation_time_min?: number | null;
  published?: boolean;
  source_provider: SourceProviderEnum;
  author?: number | null;
  image?: Blob;
  external_url?: string | null;
};
export type RecipeMealRequest = {
  recipe_template: RecipeTemplateSlimRequest;
  portions: number;
};
export type PatchedRecipeMealRequest = {
  recipe_template?: RecipeTemplateSlimRequest;
  portions?: number;
};
export type PaginatedRecipeTagList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: RecipeTag[];
};
export type PaginatedRecipeTemplateList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: RecipeTemplate[];
};
export type PatchedRecipeTemplateRequest = {
  id?: number;
  name?: string;
  cooking_time_min?: number | null;
  preparation_time_min?: number | null;
  tags?: RecipeTagRequest[];
  published?: boolean;
  source_provider?: SourceProviderEnum;
  author?: number | null;
  image?: Blob;
  external_url?: string | null;
};
export type FoodSearchAutoComplete = {
  suggestions: string[];
};
export type SingleFoodMeal = {
  id?: number;
  ingredient: Ingredient;
  kcal: number;
  protein: number;
  carbohydrates: number;
  fat: number;
  fiber: number;
};
export type PaginatedSingleFoodMealList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: SingleFoodMeal[];
};
export type SingleFoodMealRequest = {
  id?: number;
};
export type PatchedSingleFoodMealRequest = {
  id?: number;
};
export type PaginatedSuggestedServingList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: SuggestedServing[];
};
export type PaginatedSuggestedServingSearchList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: SuggestedServingSearch[];
};
export type UserBodyFatPercentage = {
  id: number;
  created_at?: string;
  value: number;
  user: number;
};
export type PaginatedUserBodyFatPercentageList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: UserBodyFatPercentage[];
};
export type UserBodyFatPercentageRequest = {
  created_at?: string;
  value: number;
  user: number;
};
export type PatchedUserBodyFatPercentageRequest = {
  created_at?: string;
  value?: number;
  user?: number;
};
export type BodyAreaEnum = "WAIST" | "NECK" | "HIPS" | "SUPRAILIAC" | "UMBILICAL";
export type UserDistance = {
  id: number;
  created_at?: string;
  value: number;
  body_area: BodyAreaEnum;
  user: number;
};
export type PaginatedUserDistanceList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: UserDistance[];
};
export type UserDistanceRequest = {
  created_at?: string;
  value: number;
  body_area: BodyAreaEnum;
  user: number;
};
export type PatchedUserDistanceRequest = {
  created_at?: string;
  value?: number;
  body_area?: BodyAreaEnum;
  user?: number;
};
export type UserSleep = {
  id: number;
  created_at?: string;
  value: number;
  user: number;
};
export type PaginatedUserSleepList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: UserSleep[];
};
export type UserSleepRequest = {
  created_at?: string;
  value: number;
  user: number;
};
export type PatchedUserSleepRequest = {
  created_at?: string;
  value?: number;
  user?: number;
};
export type ValueEnum = "VERY_LOW" | "LOW" | "MEDIUM" | "HIGH" | "VERY_HIGH";
export type UserStress = {
  id: number;
  created_at?: string;
  value: ValueEnum;
  user: number;
};
export type PaginatedUserStressList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: UserStress[];
};
export type UserStressRequest = {
  created_at?: string;
  value: ValueEnum;
  user: number;
};
export type PatchedUserStressRequest = {
  created_at?: string;
  value?: ValueEnum;
  user?: number;
};
export type MeasurementTypeEnum = "WEIGHT" | "WAIST_CIRCUMFERENCE" | "SLEEP" | "STRESS";
export type DeleteMeasurement = {
  user_id: number;
  measurement_type: MeasurementTypeEnum;
  measurement_id: number;
};
export type DeleteMeasurementRequest = {
  user_id: number;
  measurement_type: MeasurementTypeEnum;
  measurement_id: number;
};
export type UserWeight = {
  id: number;
  created_at?: string;
  value: number;
  user: number;
};
export type PaginatedUserWeightList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: UserWeight[];
};
export type UserWeightRequest = {
  created_at?: string;
  value: number;
  user: number;
};
export type PatchedUserWeightRequest = {
  created_at?: string;
  value?: number;
  user?: number;
};
export type AutoPlannerRequest = {
  date: string;
  user: number;
  meal_slot_ids?: number[];
};
export type AutoPlanDaysRequest = {
  start_date_inclusive: string;
  num_days: number;
  delete_existing_plans?: boolean;
};
export type CalendarItemStatusEnum = "PLANNED" | "EATEN" | "SKIPPED";
export type Meal =
  | ({
      content_type: "QuickAddMeal";
    } & QuickAddMeal)
  | ({
      content_type: "RecipeMeal";
    } & RecipeMeal)
  | ({
      content_type: "SingleFoodMeal";
    } & SingleFoodMeal);
export type CalendarItemContentTypeEnum = "quickaddmeal" | "recipemeal" | "singlefoodmeal";
export type CalendarItem = {
  id?: number;
  status?: CalendarItemStatusEnum;
  meal_slot: number;
  meal_moment: string;
  meal: Meal;
  content_type: CalendarItemContentTypeEnum;
  calendar_day: number;
  object_id: number;
  modified: string;
};
export type CalendarDay = {
  id?: number;
  user: number;
  date: string;
  calendar_items: CalendarItem[];
  note?: string | null;
};
export type PaginatedCalendarDayList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: CalendarDay[];
};
export type CalendarDayRequest = {
  id?: number;
  user: number;
  date: string;
  note?: string | null;
};
export type PatchedCalendarDayRequest = {
  id?: number;
  user?: number;
  date?: string;
  note?: string | null;
};
export type PaginatedCalendarItemList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: CalendarItem[];
};
export type CalendarItemRequest = {
  id?: number;
  status?: CalendarItemStatusEnum;
  meal_slot: number;
  content_type: CalendarItemContentTypeEnum;
  calendar_day: number;
  object_id: number;
};
export type PatchedCalendarItemRequest = {
  id?: number;
  status?: CalendarItemStatusEnum;
  meal_slot?: number;
  content_type?: CalendarItemContentTypeEnum;
  calendar_day?: number;
  object_id?: number;
};
export type CopyFromDate = {
  user: number;
  from_calendar_day_id: number;
  to_date: string;
};
export type CopyFromDateRequest = {
  user: number;
  from_calendar_day_id: number;
  to_date: string;
};
export type MealMomentEnum =
  | "BREAKFAST"
  | "MORNING_SNACK"
  | "LUNCH"
  | "AFTERNOON_SNACK"
  | "DINNER"
  | "SNACK"
  | "LATE_SNACK";
export type CopyMealsToMealMoment = {
  user: number;
  from_calendar_day_id: number;
  to_calendar_day: string;
  meal_moment: MealMomentEnum;
};
export type CopyMealsToMealMomentRequest = {
  user: number;
  from_calendar_day_id: number;
  to_calendar_day: string;
  meal_moment: MealMomentEnum;
};
export type RecipeMealPotions = {
  portions: number;
};
export type RecipeMealPotionsRequest = {
  portions: number;
};
export type CategoryEnum =
  | "ASIAN"
  | "BAKING"
  | "BREAD"
  | "CHIPS"
  | "CHOCOLATE"
  | "COFFEE"
  | "CRACKER"
  | "DAIRY"
  | "DELI"
  | "DRINK"
  | "FREEZER"
  | "FRUIT"
  | "HERB"
  | "MEAT"
  | "MEXICAN"
  | "NUT"
  | "OAT"
  | "OIL"
  | "ONLINE"
  | "PASTA"
  | "POWERSLIM"
  | "PRESERVED"
  | "READY"
  | "REFRIGERATED"
  | "RICE"
  | "SAUCE"
  | "SEED"
  | "SOUP"
  | "SPICE"
  | "SPREAD"
  | "VEGETABLE"
  | "WHEAT"
  | "WINE"
  | "WRAP"
  | "OTHER";
export type GroceryListItemUpdate = {
  id: number;
  suggested_serving: SuggestedServing;
  name: string;
  category: CategoryEnum;
  checked?: boolean;
  quantity: number;
  grocery_list: number;
};
export type GroceryListListRetrieve = {
  id: number;
  items: GroceryListItemUpdate[];
  start_date: string;
  end_date: string;
};
export type PaginatedGroceryListListRetrieveList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: GroceryListListRetrieve[];
};
export type GroceryListUpdateCreateRequest = {
  start_date: string;
  end_date: string;
  user: number;
};
export type PatchedGroceryListItemUpdateRequest = {
  suggested_serving?: SuggestedServingRequest;
  name?: string;
  category?: CategoryEnum;
  checked?: boolean;
  quantity?: number;
  grocery_list?: number;
};
export type PlanGeneratedRecipeMeal = {
  recipe_template: RecipeTemplate;
  ingredients: GeneratedRecipeMealIngredient[];
  directions: GeneratedRecipeMealDirection[];
  calendar_day_id: number;
  meal_slot_specification_id: number;
  portions_to_plan?: number;
  portions: number;
  kcal: number;
  protein: number;
  fat: number;
  carbohydrates: number;
  fiber: number;
};
export type PlanGeneratedRecipeMealRequest = {
  recipe_template: RecipeTemplateRequest;
  ingredients: GeneratedRecipeMealIngredientRequest[];
  directions: GeneratedRecipeMealDirectionRequest[];
  calendar_day_id: number;
  meal_slot_specification_id: number;
  portions_to_plan?: number;
  portions: number;
  kcal: number;
  protein: number;
  fat: number;
  carbohydrates: number;
  fiber: number;
};
export type PlanSingleFoodMeal = {
  quantity: number;
  suggested_serving_id: number;
  calendar_day_id: number;
  meal_slot_id: number;
};
export type PlanSingleFoodMealRequest = {
  quantity: number;
  suggested_serving_id: number;
  calendar_day_id: number;
  meal_slot_id: number;
};
export type SaveAndPlanRecipeTemplate = {
  id: number;
  name: string;
  calendar_day_id: number;
  meal_slot_id: number;
  existing_recipe_template_id?: number;
};
export type SaveAndPlanRecipeTemplateRequest = {
  id: number;
  name: string;
  calendar_day_id: number;
  meal_slot_id: number;
  existing_recipe_template_id?: number;
};
export type TokenCreate = {
  magic_link_token: string;
};
export type TokenCreateRequest = {
  magic_link_token: string;
};
export type AggregateUsageEnum = "last_during_period" | "last_ever" | "max" | "sum";
export type BillingSchemeEnum = "per_unit" | "tiered";
export type IntervalEnum = "day" | "month" | "week" | "year";
export type TiersModeEnum = "graduated" | "volume";
export type UsageTypeEnum = "licensed" | "metered";
export type DjstripePlan = {
  djstripe_id: number;
  djstripe_created: string;
  djstripe_updated: string;
  id: string;
  livemode?: boolean | null;
  created?: string | null;
  metadata?: any | null;
  description?: string | null;
  active: boolean;
  aggregate_usage?: AggregateUsageEnum | BlankEnum;
  amount?: string | null;
  amount_decimal?: string | null;
  billing_scheme?: BillingSchemeEnum | BlankEnum;
  currency: string;
  interval: IntervalEnum;
  interval_count?: number | null;
  nickname?: string;
  tiers?: any | null;
  tiers_mode?: (TiersModeEnum | BlankEnum | NullEnum) | null;
  transform_usage?: any | null;
  trial_period_days?: number | null;
  usage_type?: UsageTypeEnum;
  djstripe_owner_account?: string | null;
  product?: string | null;
};
export type TypeEnum = "one_time" | "recurring";
export type DjstripePrice = {
  djstripe_id: number;
  djstripe_created: string;
  djstripe_updated: string;
  id: string;
  livemode?: boolean | null;
  created?: string | null;
  metadata?: any | null;
  description?: string | null;
  active: boolean;
  currency: string;
  nickname?: string;
  recurring?: any | null;
  type: TypeEnum;
  unit_amount?: number | null;
  unit_amount_decimal?: string | null;
  billing_scheme?: BillingSchemeEnum | BlankEnum;
  lookup_key?: string | null;
  tiers?: any | null;
  tiers_mode?: (TiersModeEnum | BlankEnum | NullEnum) | null;
  transform_quantity?: any | null;
  djstripe_owner_account?: string | null;
  product: string;
};
export type ProrationBehaviorEnum = "always_invoice" | "create_prorations" | "none";
export type DjstripeSubscriptionItem = {
  djstripe_id: number;
  plan: DjstripePlan;
  price: DjstripePrice;
  djstripe_created: string;
  djstripe_updated: string;
  id: string;
  livemode?: boolean | null;
  created?: string | null;
  metadata?: any | null;
  description?: string | null;
  billing_thresholds?: any | null;
  proration_behavior?: ProrationBehaviorEnum | BlankEnum;
  proration_date?: string | null;
  quantity?: number | null;
  djstripe_owner_account?: string | null;
  subscription: string;
  tax_rates?: number[];
};
export type CollectionMethodEnum = "charge_automatically" | "send_invoice";
export type DjstripeSubscriptionStatusEnum =
  | "active"
  | "canceled"
  | "incomplete"
  | "incomplete_expired"
  | "past_due"
  | "trialing"
  | "unpaid";
export type DjstripeSubscription = {
  djstripe_id: number;
  items: DjstripeSubscriptionItem[];
  djstripe_created: string;
  djstripe_updated: string;
  id: string;
  livemode?: boolean | null;
  created?: string | null;
  metadata?: any | null;
  description?: string | null;
  application_fee_percent?: string | null;
  billing_cycle_anchor?: string | null;
  billing_thresholds?: any | null;
  cancel_at?: string | null;
  cancel_at_period_end?: boolean;
  canceled_at?: string | null;
  collection_method: CollectionMethodEnum;
  current_period_end: string;
  current_period_start: string;
  days_until_due?: number | null;
  discount?: any | null;
  ended_at?: string | null;
  next_pending_invoice_item_invoice?: string | null;
  pause_collection?: any | null;
  pending_invoice_item_interval?: any | null;
  pending_update?: any | null;
  proration_behavior?: ProrationBehaviorEnum | BlankEnum;
  proration_date?: string | null;
  quantity?: number | null;
  start_date?: string | null;
  status: DjstripeSubscriptionStatusEnum;
  trial_end?: string | null;
  trial_start?: string | null;
  djstripe_owner_account?: string | null;
  customer: string;
  default_payment_method?: string | null;
  default_source?: string | null;
  latest_invoice?: string | null;
  pending_setup_intent?: string | null;
  plan?: number | null;
  schedule?: number | null;
  default_tax_rates?: number[];
};
export type TaxExemptEnum = "exempt" | "none" | "reverse";
export type DjstripeCustomer = {
  djstripe_id: number;
  subscriptions: DjstripeSubscription[];
  djstripe_created: string;
  djstripe_updated: string;
  id: string;
  livemode?: boolean | null;
  created?: string | null;
  metadata?: any | null;
  description?: string | null;
  address?: any | null;
  balance?: number | null;
  currency?: string;
  delinquent?: boolean | null;
  deleted?: boolean | null;
  coupon_start: string | null;
  coupon_end: string | null;
  discount?: any | null;
  email?: string;
  invoice_prefix?: string;
  invoice_settings?: any | null;
  name?: string;
  phone?: string;
  preferred_locales?: any | null;
  shipping?: any | null;
  tax_exempt?: TaxExemptEnum;
  date_purged: string | null;
  djstripe_owner_account: string | null;
  default_source?: string | null;
  coupon?: number | null;
  default_payment_method?: string | null;
  subscriber: number | null;
};
export type MealTypeEnum = "BREAKFAST" | "LUNCH" | "DINNER" | "SNACK";
export type MealSlotSpecification = {
  id: number;
  name?: string;
  meal_type: MealTypeEnum;
  order: number;
  meal_moment: MealMomentEnum;
  kcal?: number | null;
  protein?: number | null;
  carbohydrates?: number | null;
  fat?: number | null;
  fiber?: number | null;
  nutrition_day_plan: number;
};
export type NutritionDayPlan = {
  id: number;
  name?: string;
  meal_slot_specifications: MealSlotSpecification[];
};
export type WeeklyNutritionPlan = {
  id: number;
  name?: string;
  monday: NutritionDayPlan;
  tuesday: NutritionDayPlan;
  wednesday: NutritionDayPlan;
  thursday: NutritionDayPlan;
  friday: NutritionDayPlan;
  saturday: NutritionDayPlan;
  sunday: NutritionDayPlan;
};
export type GenderEnum = "MALE" | "FEMALE";
export type ActivityEnum = "SEDENTARY" | "MILDLY_ACTIVE" | "ACTIVE" | "VERY_ACTIVE";
export type SustainabilityEnum = "SUSTAINABLE" | "OPTIMAL";
export type DietEnum =
  | "VEGAN"
  | "LACTO_VEGETARIAN"
  | "OVO_VEGETARIAN"
  | "VEGETARIAN"
  | "PESCATARIAN"
  | "OMNIVORE"
  | "HALAL";
export type ExerciseTemplate = {
  id: number;
  exercise_name: string;
  kcal_per_kg_per_min: number;
};
export type ExerciseInstanceListRetrieve = {
  id: number;
  intake: number;
  exercise: ExerciseTemplate;
  minutes_per_session: number;
  sessions_per_week: number;
};
export type MainGoalEnum = "GAIN_MUSCLE" | "LOSE_WEIGHT" | "RECOMPOSITION";
export type GoalEnum = "GAIN_MUSCLE" | "LOSE_WEIGHT" | "RECOMPOSITION";
export type UserProfile = {
  id: number;
  weekly_nutrition_plan: WeeklyNutritionPlan | null;
  gender: GenderEnum;
  activity: ActivityEnum;
  sustainability: SustainabilityEnum;
  diet: DietEnum;
  weight: number;
  body_fat_percentage: number;
  food_intolerances_gluten?: boolean;
  food_intolerances_lactose?: boolean;
  food_intolerances_nut?: boolean;
  food_intolerances_crustaceans_shellfish?: boolean;
  exercise_instances: ExerciseInstanceListRetrieve[];
  is_keto_user?: boolean;
  main_goal?: MainGoalEnum;
  goal?: (GoalEnum | NullEnum) | null;
  age?: number | null;
  height_in_cm?: number | null;
  goal_weight_in_kg?: number | null;
  goal_weekly_change_in_kg?: number | null;
};
export type ClientAuthenticationMethodEnum = "MAGIC_LINK" | "OTP";
export type Organisation = {
  id: number;
  name: string;
  primary_colour?: string | null;
  secondary_colour?: string | null;
  logo?: string;
  logo_visible?: boolean;
  clients_are_diy?: boolean;
  can_send_email_campaigns?: boolean | null;
  client_authentication_method?: ClientAuthenticationMethodEnum;
  custom_notes_message?: string | null;
  support_url?: string | null;
  support_email?: string | null;
  subdomain?: string | null;
  feature_flag_disable_product_logging?: boolean;
};
export type Coach = {
  organisation: Organisation;
  account_enabled?: boolean;
  uses_imperial_measurements?: boolean;
};
export type User = {
  id: number;
  email: string;
  first_name?: string;
  last_name?: string;
  customer: DjstripeCustomer[];
  is_coach?: boolean;
  account_enabled?: boolean;
  payment_status_override?: boolean;
  intake?: UserProfile;
  organisation: Organisation;
  coach: Coach;
  ingredients_to_avoid?: string[];
  uses_imperial_measurements?: boolean;
};
export type PaginatedUserList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: User[];
};
export type CustomUserCreate = {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  intake: UserProfile;
};
export type UserProfileRequest = {
  user_id?: number;
  gender: GenderEnum;
  activity: ActivityEnum;
  sustainability: SustainabilityEnum;
  diet: DietEnum;
  weight: number;
  body_fat_percentage: number;
  food_intolerances_gluten?: boolean;
  food_intolerances_lactose?: boolean;
  food_intolerances_nut?: boolean;
  food_intolerances_crustaceans_shellfish?: boolean;
  is_keto_user?: boolean;
  main_goal?: MainGoalEnum;
  goal?: (GoalEnum | NullEnum) | null;
  age?: number | null;
  height_in_cm?: number | null;
  goal_weight_in_kg?: number | null;
  goal_weekly_change_in_kg?: number | null;
};
export type CustomUserCreateRequest = {
  email: string;
  first_name: string;
  last_name: string;
  intake: UserProfileRequest;
};
export type OrganisationRequest = {
  name: string;
  primary_colour?: string | null;
  secondary_colour?: string | null;
  logo?: Blob;
  logo_visible?: boolean;
  clients_are_diy?: boolean;
  can_send_email_campaigns?: boolean | null;
  client_authentication_method?: ClientAuthenticationMethodEnum;
  custom_notes_message?: string | null;
  support_url?: string | null;
  support_email?: string | null;
  subdomain?: string | null;
  feature_flag_disable_product_logging?: boolean;
};
export type CoachRequest = {
  organisation: OrganisationRequest;
  account_enabled?: boolean;
  uses_imperial_measurements?: boolean;
};
export type UserRequest = {
  email: string;
  first_name?: string;
  last_name?: string;
  is_coach?: boolean;
  account_enabled?: boolean;
  payment_status_override?: boolean;
  intake?: UserProfileRequest;
  organisation: OrganisationRequest;
  coach: CoachRequest;
  ingredients_to_avoid?: string[];
  uses_imperial_measurements?: boolean;
};
export type PatchedUserRequest = {
  email?: string;
  first_name?: string;
  last_name?: string;
  is_coach?: boolean;
  account_enabled?: boolean;
  payment_status_override?: boolean;
  intake?: UserProfileRequest;
  organisation?: OrganisationRequest;
  coach?: CoachRequest;
  ingredients_to_avoid?: string[];
  uses_imperial_measurements?: boolean;
};
export type Activation = {
  uid: string;
  token: string;
};
export type ActivationRequest = {
  uid: string;
  token: string;
};
export type SendEmailReset = {
  email: string;
};
export type SendEmailResetRequest = {
  email: string;
};
export type UsernameResetConfirm = {
  new_email: string;
};
export type UsernameResetConfirmRequest = {
  new_email: string;
};
export type PasswordResetConfirm = {
  uid: string;
  token: string;
  new_password: string;
};
export type PasswordResetConfirmRequest = {
  uid: string;
  token: string;
  new_password: string;
};
export type SetUsername = {
  current_password: string;
  new_email: string;
};
export type SetUsernameRequest = {
  current_password: string;
  new_email: string;
};
export type SetPassword = {
  new_password: string;
  current_password: string;
};
export type SetPasswordRequest = {
  new_password: string;
  current_password: string;
};
export type Brand = {
  support_url: string;
  uses_pwa: boolean;
  logo?: string;
  primary_colour?: string;
};
export type PaginatedBrandList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: Brand[];
};
export type CancelSubscription = {
  user: number;
  deal_cancel_at?: string;
};
export type CancelSubscriptionRequest = {
  user: number;
  deal_cancel_at?: string;
};
export type AuthenticationMethodEnum = "MAGIC_LINK" | "OTP";
export type LoginSignupPortalResponse = {
  authentication_method: AuthenticationMethodEnum;
};
export type LoginSignupPortalRequest = {
  email: string;
  organisation_subdomain: string;
  redirect_url: string;
};
export type MagicLinkCreate = {
  email: string;
  redirect_url: string;
};
export type MagicLinkCreateRequest = {
  email: string;
  redirect_url: string;
};
export type SendOtp = {
  email: string;
};
export type SendOtpRequest = {
  email: string;
};
export type PaginatedExerciseInstanceListRetrieveList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: ExerciseInstanceListRetrieve[];
};
export type ExerciseInstanceCreateUpdate = {
  id: number;
  minutes_per_session: number;
  sessions_per_week: number;
  intake: number;
  exercise: number;
};
export type ExerciseInstanceCreateUpdateRequest = {
  minutes_per_session: number;
  sessions_per_week: number;
  intake: number;
  exercise: number;
};
export type PatchedExerciseInstanceCreateUpdateRequest = {
  minutes_per_session?: number;
  sessions_per_week?: number;
  intake?: number;
  exercise?: number;
};
export type PaginatedExerciseTemplateList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: ExerciseTemplate[];
};
export type PaginatedMealSlotSpecificationList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: MealSlotSpecification[];
};
export type MealSlotSpecificationRequest = {
  name?: string;
  meal_type: MealTypeEnum;
  order: number;
  meal_moment: MealMomentEnum;
  kcal?: number | null;
  protein?: number | null;
  carbohydrates?: number | null;
  fat?: number | null;
  fiber?: number | null;
  nutrition_day_plan: number;
};
export type PatchedMealSlotSpecificationRequest = {
  name?: string;
  meal_type?: MealTypeEnum;
  order?: number;
  meal_moment?: MealMomentEnum;
  kcal?: number | null;
  protein?: number | null;
  carbohydrates?: number | null;
  fat?: number | null;
  fiber?: number | null;
  nutrition_day_plan?: number;
};
export type PaginatedNutritionDayPlanList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: NutritionDayPlan[];
};
export type NutritionDayPlanRequest = {
  name?: string;
};
export type PatchedNutritionDayPlanRequest = {
  name?: string;
};
export type UpdateCreateOrganisation = {
  id?: number;
  name: string;
  primary_colour?: string | null;
  secondary_colour?: string | null;
  logo?: string;
  support_url?: string | null;
  support_email?: string | null;
  subdomain?: string | null;
  logo_visible?: boolean;
  clients_are_diy?: boolean;
  can_send_email_campaigns?: boolean;
  client_authentication_method?: ClientAuthenticationMethodEnum;
  uses_pwa?: boolean;
  feature_flag_disable_product_logging?: boolean;
};
export type UpdateCreateOrganisationRequest = {
  id?: number;
  name: string;
  primary_colour?: string | null;
  secondary_colour?: string | null;
  logo?: Blob;
  support_url?: string | null;
  support_email?: string | null;
  subdomain?: string | null;
  logo_visible?: boolean;
  clients_are_diy?: boolean;
  can_send_email_campaigns?: boolean;
  client_authentication_method?: ClientAuthenticationMethodEnum;
  uses_pwa?: boolean;
  feature_flag_disable_product_logging?: boolean;
};
export type PatchedUpdateCreateOrganisationRequest = {
  id?: number;
  name?: string;
  primary_colour?: string | null;
  secondary_colour?: string | null;
  logo?: Blob;
  support_url?: string | null;
  support_email?: string | null;
  subdomain?: string | null;
  logo_visible?: boolean;
  clients_are_diy?: boolean;
  can_send_email_campaigns?: boolean;
  client_authentication_method?: ClientAuthenticationMethodEnum;
  uses_pwa?: boolean;
  feature_flag_disable_product_logging?: boolean;
};
export type SlimClient = {
  id: number;
  first_name?: string;
  last_name?: string;
  email: string;
};
export type PaginatedSlimClientList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: SlimClient[];
};
export type SlimClientRequest = {
  first_name?: string;
  last_name?: string;
  email: string;
};
export type PatchedSlimClientRequest = {
  first_name?: string;
  last_name?: string;
  email?: string;
};
export type SetIngredientToAvoid = {
  ingredient_to_avoid_ids: string[];
};
export type SetIngredientToAvoidRequest = {
  ingredient_to_avoid_ids: string[];
};
export type StripeCustomerPortal = {
  portal_url: string;
};
export type PaginatedUserProfileList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: UserProfile[];
};
export type PatchedUserProfileRequest = {
  user_id?: number;
  gender?: GenderEnum;
  activity?: ActivityEnum;
  sustainability?: SustainabilityEnum;
  diet?: DietEnum;
  weight?: number;
  body_fat_percentage?: number;
  food_intolerances_gluten?: boolean;
  food_intolerances_lactose?: boolean;
  food_intolerances_nut?: boolean;
  food_intolerances_crustaceans_shellfish?: boolean;
  is_keto_user?: boolean;
  main_goal?: MainGoalEnum;
  goal?: (GoalEnum | NullEnum) | null;
  age?: number | null;
  height_in_cm?: number | null;
  goal_weight_in_kg?: number | null;
  goal_weekly_change_in_kg?: number | null;
};
export type UserDislikeRecipe = {
  id: number;
  recipe: number;
  user: number;
};
export type PaginatedUserDislikeRecipeList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: UserDislikeRecipe[];
};
export type UserDislikeRecipeRequest = {
  recipe: number;
  user: number;
};
export type PatchedUserDislikeRecipeRequest = {
  recipe?: number;
  user?: number;
};
export type UserFavouriteRecipe = {
  id: number;
  recipe: number;
  user: number;
};
export type PaginatedUserFavouriteRecipeList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: UserFavouriteRecipe[];
};
export type UserFavouriteRecipeRequest = {
  recipe: number;
  user: number;
};
export type PatchedUserFavouriteRecipeRequest = {
  recipe?: number;
  user?: number;
};
export type VerifyOtpResponse = {
  auth_token: string;
};
export type VerifyOtpRequest = {
  email: string;
  otp: string;
};
export type PaginatedWeeklyNutritionPlanList = {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: WeeklyNutritionPlan[];
};
export type WeeklyNutritionPlanCreate = {
  id: number;
  monday?: number;
  tuesday?: number;
  wednesday?: number;
  thursday?: number;
  friday?: number;
  saturday?: number;
  sunday?: number;
  client_intake?: number;
};
export type WeeklyNutritionPlanCreateRequest = {
  monday?: number;
  tuesday?: number;
  wednesday?: number;
  thursday?: number;
  friday?: number;
  saturday?: number;
  sunday?: number;
  client_intake?: number;
};
export type DaysToUpdateToNutritionDayPlanIdEnum =
  | "monday"
  | "tuesday"
  | "wednesday"
  | "thursday"
  | "friday"
  | "saturday"
  | "sunday";
export type WeeklyNutritionPlanUpdate = {
  name?: string;
  days_to_update_to_nutrition_day_plan_id: DaysToUpdateToNutritionDayPlanIdEnum[];
  nutrition_day_plan_id: number;
};
export type WeeklyNutritionPlanUpdateRequest = {
  name?: string;
  days_to_update_to_nutrition_day_plan_id: DaysToUpdateToNutritionDayPlanIdEnum[];
  nutrition_day_plan_id: number;
};
export const {
  useCheckoutSubscriptionVerificationCreateMutation,
  useFoodCreateRecipeCreateMutation,
  useFoodFoodListQuery,
  useFoodFoodRetrieveQuery,
  useFoodGenerateMealsListQuery,
  useFoodGenerateMealsCreateMutation,
  useFoodIngredientListQuery,
  useFoodIngredientCreateMutation,
  useFoodIngredientRetrieveQuery,
  useFoodIngredientUpdateMutation,
  useFoodIngredientPartialUpdateMutation,
  useFoodIngredientDestroyMutation,
  useFoodIngredientToAvoidListQuery,
  useFoodIngredientToAvoidRetrieveQuery,
  useFoodPreferredSuggestedServingsListQuery,
  useFoodPreferredSuggestedServingsCreateMutation,
  useFoodPreferredSuggestedServingsRetrieveQuery,
  useFoodPreferredSuggestedServingsUpdateMutation,
  useFoodPreferredSuggestedServingsPartialUpdateMutation,
  useFoodPreferredSuggestedServingsDestroyMutation,
  useFoodQuickAddMealListQuery,
  useFoodQuickAddMealCreateMutation,
  useFoodQuickAddMealRetrieveQuery,
  useFoodQuickAddMealUpdateMutation,
  useFoodQuickAddMealPartialUpdateMutation,
  useFoodQuickAddMealDestroyMutation,
  useFoodRecipeFeedbackLineItemListQuery,
  useFoodRecipeFeedbackLineItemCreateMutation,
  useFoodRecipeFeedbackLineItemRetrieveQuery,
  useFoodRecipeFeedbackLineItemUpdateMutation,
  useFoodRecipeFeedbackLineItemPartialUpdateMutation,
  useFoodRecipeFeedbackLineItemDestroyMutation,
  useFoodRecipeMealListQuery,
  useFoodRecipeMealCreateMutation,
  useFoodRecipeMealRetrieveQuery,
  useFoodRecipeMealUpdateMutation,
  useFoodRecipeMealPartialUpdateMutation,
  useFoodRecipeMealDestroyMutation,
  useFoodRecipeTagListQuery,
  useFoodRecipeTagRetrieveQuery,
  useFoodRecipeTemplateListQuery,
  useFoodRecipeTemplateCreateMutation,
  useFoodRecipeTemplateRetrieveQuery,
  useFoodRecipeTemplateUpdateMutation,
  useFoodRecipeTemplatePartialUpdateMutation,
  useFoodRecipeTemplateDestroyMutation,
  useFoodSearchListQuery,
  useFoodSearchAutocompleteRetrieveQuery,
  useFoodSearchByBarcodeRetrieveQuery,
  useFoodSingleFoodMealListQuery,
  useFoodSingleFoodMealCreateMutation,
  useFoodSingleFoodMealRetrieveQuery,
  useFoodSingleFoodMealUpdateMutation,
  useFoodSingleFoodMealPartialUpdateMutation,
  useFoodSingleFoodMealDestroyMutation,
  useFoodSuggestedServingListQuery,
  useFoodSuggestedServingRetrieveQuery,
  useFoodSuggestedServingSearchListQuery,
  useFoodSuggestedServingSearchRetrieveQuery,
  useMeasurementsUserBodyFatPercentageListQuery,
  useMeasurementsUserBodyFatPercentageCreateMutation,
  useMeasurementsUserBodyFatPercentageRetrieveQuery,
  useMeasurementsUserBodyFatPercentageUpdateMutation,
  useMeasurementsUserBodyFatPercentagePartialUpdateMutation,
  useMeasurementsUserBodyFatPercentageDestroyMutation,
  useMeasurementsUserDistanceListQuery,
  useMeasurementsUserDistanceCreateMutation,
  useMeasurementsUserDistanceRetrieveQuery,
  useMeasurementsUserDistanceUpdateMutation,
  useMeasurementsUserDistancePartialUpdateMutation,
  useMeasurementsUserDistanceDestroyMutation,
  useMeasurementsUserSleepListQuery,
  useMeasurementsUserSleepCreateMutation,
  useMeasurementsUserSleepRetrieveQuery,
  useMeasurementsUserSleepUpdateMutation,
  useMeasurementsUserSleepPartialUpdateMutation,
  useMeasurementsUserSleepDestroyMutation,
  useMeasurementsUserStressListQuery,
  useMeasurementsUserStressCreateMutation,
  useMeasurementsUserStressRetrieveQuery,
  useMeasurementsUserStressUpdateMutation,
  useMeasurementsUserStressPartialUpdateMutation,
  useMeasurementsUserStressDestroyMutation,
  useMeasurementsDeleteMeasurementCreateMutation,
  useMeasurementsUserWeightListQuery,
  useMeasurementsUserWeightCreateMutation,
  useMeasurementsUserWeightRetrieveQuery,
  useMeasurementsUserWeightUpdateMutation,
  useMeasurementsUserWeightPartialUpdateMutation,
  useMeasurementsUserWeightDestroyMutation,
  usePlannerAutoPlanDayCreateMutation,
  usePlannerAutoPlanDaysCreateMutation,
  usePlannerCalendarDayListQuery,
  usePlannerCalendarDayCreateMutation,
  usePlannerCalendarDayRetrieveQuery,
  usePlannerCalendarDayUpdateMutation,
  usePlannerCalendarDayPartialUpdateMutation,
  usePlannerCalendarDayDestroyMutation,
  usePlannerCalendarItemListQuery,
  usePlannerCalendarItemCreateMutation,
  usePlannerCalendarItemRetrieveQuery,
  usePlannerCalendarItemUpdateMutation,
  usePlannerCalendarItemPartialUpdateMutation,
  usePlannerCalendarItemDestroyMutation,
  usePlannerCopyMealsFromDateCreateMutation,
  usePlannerCopyMealsToMealMomentCreateMutation,
  usePlannerEditRecipeMealPortionsUpdateMutation,
  usePlannerGroceryListListQuery,
  usePlannerGroceryListCreateMutation,
  usePlannerGroceryListRetrieveQuery,
  usePlannerGroceryListUpdateMutation,
  usePlannerGroceryListPartialUpdateMutation,
  usePlannerGroceryListDestroyMutation,
  usePlannerGroceryListItemPartialUpdateMutation,
  usePlannerPlanGeneratedRecipeMealCreateMutation,
  usePlannerPlanSingleFoodMealCreateMutation,
  usePlannerSaveAndPlanRecipeMealCreateMutation,
  useSchemaRetrieveQuery,
  useUsersAuthTokenLoginCreateMutation,
  useUsersAuthTokenLogoutCreateMutation,
  useUsersAuthUsersListQuery,
  useUsersAuthUsersCreateMutation,
  useUsersAuthUsersRetrieveQuery,
  useUsersAuthUsersUpdateMutation,
  useUsersAuthUsersPartialUpdateMutation,
  useUsersAuthUsersDestroyMutation,
  useUsersAuthUsersActivationCreateMutation,
  useUsersAuthUsersMeRetrieveQuery,
  useUsersAuthUsersMeUpdateMutation,
  useUsersAuthUsersMePartialUpdateMutation,
  useUsersAuthUsersMeDestroyMutation,
  useUsersAuthUsersResendActivationCreateMutation,
  useUsersAuthUsersResetEmailCreateMutation,
  useUsersAuthUsersResetEmailConfirmCreateMutation,
  useUsersAuthUsersResetPasswordCreateMutation,
  useUsersAuthUsersResetPasswordConfirmCreateMutation,
  useUsersAuthUsersSetEmailCreateMutation,
  useUsersAuthUsersSetPasswordCreateMutation,
  useUsersBrandListQuery,
  useUsersBrandRetrieveQuery,
  useUsersCancelDealCreateMutation,
  useUsersClientsListQuery,
  useUsersClientsCreateMutation,
  useUsersClientsRetrieveQuery,
  useUsersClientsUpdateMutation,
  useUsersClientsPartialUpdateMutation,
  useUsersClientsDestroyMutation,
  useUsersCreateLoginSignupPortalCreateMutation,
  useUsersCreateMagicLinkCreateMutation,
  useUsersCreateOtpCreateMutation,
  useUsersCustomerPortalRedirectRetrieveQuery,
  useUsersExerciseInstanceListQuery,
  useUsersExerciseInstanceCreateMutation,
  useUsersExerciseInstanceRetrieveQuery,
  useUsersExerciseInstanceUpdateMutation,
  useUsersExerciseInstancePartialUpdateMutation,
  useUsersExerciseInstanceDestroyMutation,
  useUsersExerciseTemplateListQuery,
  useUsersExerciseTemplateRetrieveQuery,
  useUsersMealSlotSpecificationListQuery,
  useUsersMealSlotSpecificationCreateMutation,
  useUsersMealSlotSpecificationRetrieveQuery,
  useUsersMealSlotSpecificationUpdateMutation,
  useUsersMealSlotSpecificationPartialUpdateMutation,
  useUsersMealSlotSpecificationDestroyMutation,
  useUsersNutritionDayPlanListQuery,
  useUsersNutritionDayPlanCreateMutation,
  useUsersNutritionDayPlanRetrieveQuery,
  useUsersNutritionDayPlanUpdateMutation,
  useUsersNutritionDayPlanPartialUpdateMutation,
  useUsersNutritionDayPlanDestroyMutation,
  useUsersOrganisationCreateMutation,
  useUsersOrganisationPartialUpdateMutation,
  useUsersPaginatedclientsListQuery,
  useUsersPaginatedclientsCreateMutation,
  useUsersPaginatedclientsRetrieveQuery,
  useUsersPaginatedclientsUpdateMutation,
  useUsersPaginatedclientsPartialUpdateMutation,
  useUsersPaginatedclientsDestroyMutation,
  useUsersSetIngredientsToAvoidCreateMutation,
  useUsersStripeCustomerPortalCreateMutation,
  useUsersUserListQuery,
  useUsersUserCreateMutation,
  useUsersUserRetrieveQuery,
  useUsersUserUpdateMutation,
  useUsersUserPartialUpdateMutation,
  useUsersUserDestroyMutation,
  useUsersUserProfileListQuery,
  useUsersUserProfileCreateMutation,
  useUsersUserProfileRetrieveQuery,
  useUsersUserProfileUpdateMutation,
  useUsersUserProfilePartialUpdateMutation,
  useUsersUserProfileDestroyMutation,
  useUsersUserdislikerecipeListQuery,
  useUsersUserdislikerecipeCreateMutation,
  useUsersUserdislikerecipeRetrieveQuery,
  useUsersUserdislikerecipeUpdateMutation,
  useUsersUserdislikerecipePartialUpdateMutation,
  useUsersUserdislikerecipeDestroyMutation,
  useUsersUserfavouriterecipeListQuery,
  useUsersUserfavouriterecipeCreateMutation,
  useUsersUserfavouriterecipeRetrieveQuery,
  useUsersUserfavouriterecipeUpdateMutation,
  useUsersUserfavouriterecipePartialUpdateMutation,
  useUsersUserfavouriterecipeDestroyMutation,
  useUsersVerifyOtpCreateMutation,
  useUsersWeeklyNutritionPlanListQuery,
  useUsersWeeklyNutritionPlanCreateMutation,
  useUsersWeeklyNutritionPlanRetrieveQuery,
  useUsersWeeklyNutritionPlanUpdateMutation,
  useV2SchemaRetrieveQuery,
  useV3SchemaRetrieveQuery,
} = injectedRtkApi;
